import React from "react";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { Flex, HStack, Image, Link as ChakraLink, Modal, ModalOverlay } from "@chakra-ui/react";

import Logo from "../assets/images/logo.png";
import PanelPicnic from "../assets/images/panel_picnic.jpg";
import { useGlobalContext } from "../context/globalContext";

interface Props {}

const LayoutDynamic: React.FC<Props> = () => {
  const { isMobile, isMobileOrTablet, modalLoading } = useGlobalContext();

  return (
    <HStack bg="white" h="100%" w="100%" overflowX="hidden" spacing={0}>
      <Modal isOpen={modalLoading} onClose={() => {}}>
        <ModalOverlay zIndex={999} />
      </Modal>
      <Flex
        id="public-layout-scrollable"
        direction="column"
        align="center"
        h="100%"
        flex={4}
        px="1em"
        py={isMobile ? "1em" : "2em"}
        overflowY="scroll"
      >
        <ChakraLink as={RouterLink} to="/" replace>
          <Image src={Logo} w={isMobile ? "180px" : "200px"} mb={isMobile ? "20px" : "20px"} />
        </ChakraLink>
        <Outlet />
      </Flex>
      <Flex h="100%" flex={3} display={isMobileOrTablet ? "none" : "block"}>
        <Image src={PanelPicnic} h="100%" w="100%" objectFit="cover" />
      </Flex>
    </HStack>
  );
};

export default LayoutDynamic;
