import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Flex, Modal, ModalOverlay, VStack } from "@chakra-ui/react";

import { isTokenValid } from "../features/authentication/utils/tokens";
import { useAuthContext } from "../features/authentication/context/authContext";
import { useGlobalContext } from "../context/globalContext";

import HeaderBusiness from "../features/business/components/HeaderBusiness";
import Sidebar from "../features/business/components/Sidebar";

interface Props {}

const LayoutBusiness: React.FC<Props> = () => {
  const { account, token, clearAuth, registerBizActive } = useAuthContext();
  const { isMobileOrTablet, modalLoading } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTokenValid(token) || !account) {
      clearAuth();
      navigate("/login", { replace: true });
    } else if (account.userType === "business" || account.userType === "admin") {
      if (registerBizActive) {
        navigate("/get-started", { replace: true });
      }
    } else {
      navigate("/", { replace: true });
    }
  }, [account, registerBizActive, token, clearAuth, navigate]);

  return (
    <VStack spacing={0} h="100%">
      <Modal isOpen={modalLoading} onClose={() => {}}>
        <ModalOverlay zIndex={999} />
      </Modal>
      <HeaderBusiness />
      <Flex position="relative" w="100%" h="100%" mt="48px">
        <Sidebar />
        <Box
          ms={!isMobileOrTablet ? "280px" : "0px"}
          transition="left 0.2s ease-in-out"
          flex={1}
          p={isMobileOrTablet ? 4 : 8}
        >
          <Outlet />
        </Box>
      </Flex>
    </VStack>
  );
};

export default LayoutBusiness;
