import React, { useEffect, useState } from "react";
import { Button, Icon, Spinner, Text, VStack } from "@chakra-ui/react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { HiNoSymbol, HiOutlineCheckCircle } from "react-icons/hi2";

import { PublicAPI } from "../api";
import { AxiosError } from "axios";
import { useAuthContext } from "../features/authentication/context/authContext";

interface Props {}

const VerifyEmailPage: React.FC<Props> = () => {
  const [message, setMessage] = useState("Verifying your email...");
  const [success, setSuccess] = useState<boolean | null>(null);

  const { token } = useAuthContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const uid = searchParams.get("uid");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const res = await PublicAPI.post("/account/verify_email/", {
          uid,
        });

        setMessage(res.data.message);
        setSuccess(res.status === 200);
      } catch (e) {
        const error = e as AxiosError;
        console.log(error);
        setMessage("Something went wrong with the email verification.");
        setSuccess(false);
      }
    };

    if (uid) {
      verifyEmail();
    } else {
      setMessage("Missing required parameters.");
      setSuccess(false);
    }
  }, [uid]);

  return (
    <VStack align="center" justify="center" height="100%" spacing={8}>
      <Text fontSize="1.2em" color="gray.600" fontWeight="bold" maxW="320px" textAlign="center">
        {message}
      </Text>
      {success === null && <Spinner size="xl" color="teal.600" />}
      {success === false && <Icon as={HiNoSymbol} fontSize="4.2em" color="red.600" />}
      {success === true && <Icon as={HiOutlineCheckCircle} fontSize="4.2em" color="green.600" />}

      <Button
        colorScheme="teal"
        variant="outline"
        onClick={() => {
          navigate("/");
        }}
      >
        {token ? "Continue to app" : "Continue to login"}
      </Button>
    </VStack>
  );
};

export default VerifyEmailPage;
