import React, { useEffect, useState } from "react";
import {
  HStack,
  Icon,
  Link as ChakraLink,
  SimpleGrid,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  VStack,
  useTheme,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  HiArrowLeft,
  HiOutlineExclamationTriangle,
  HiOutlineDocumentText,
} from "react-icons/hi2";

import { ALLERGENS_CUSTOMER_CONFIG, DIETS_CONFIG } from "../../data/constants";
import { useGlobalContext } from "../../context/globalContext";
import { useAuthContext } from "../../features/authentication/context/authContext";
import { useSearchContext } from "../../features/customer/context/searchContext";
import {
  RestaurantBranch,
  RestaurantMenu,
  RestaurantMenuDetailsParams,
} from "../../features/customer/types/state";
import {
  handleGetBranchDetails,
  handleGetMenuDetails,
} from "../../features/customer/services/search";
import MenuItemCard from "../../features/customer/components/MenuItemCard";
import BranchSubheader from "../../components/BranchSubheader";
import MenuItemOverlay from "../../features/customer/components/MenuItemOverlay";
import ItemsMultiSelect from "../../components/ItemsMultiSelect";

interface Props {}

const MenuProfilePage: React.FC<Props> = () => {
  const theme = useTheme();
  const { account } = useAuthContext();
  const { isMobile } = useGlobalContext();
  const { currentBranch, currentMenuItem, setCurrentMenuItem } =
    useSearchContext();
  const [menuLoading, setMenuLoading] = useState<boolean>(true);
  const [menu, setMenu] = useState<RestaurantMenu | null>(null);
  const [menuName, setMenuName] = useState<string>("");
  const [menuNotFound, setMenuNotFound] = useState<boolean>(false);
  const [allergens, setAllergens] = useState<string[]>(
    account?.allergens || []
  );
  const [diets, setDiets] = useState<string[]>(account?.diets || []);
  const { branchUid, menuUid } = useParams();
  const [activeBranch, setActiveBranch] = useState<RestaurantBranch | null>(
    currentBranch
  );

  // get menu details
  useEffect(() => {
    if (!menuUid || !branchUid) return;

    const getMenu = async () => {
      setMenuLoading(true);
      const menu = await handleGetMenuDetails(branchUid, menuUid, {
        allergens,
        diets,
      } as RestaurantMenuDetailsParams);
      setMenuLoading(false);
      if (menu) {
        setMenuName(menu.name);
        setMenu(menu);
      } else {
        console.log("menu not found");
        setMenuNotFound(true);
      }
    };

    getMenu();
  }, [allergens, diets, branchUid, menuUid, setMenu]);

  // get branch details
  useEffect(() => {
    if (!branchUid) return;
    if (activeBranch && activeBranch.uid === branchUid) return;

    const getBranch = async () => {
      console.log("getting branch details", branchUid);
      if (!branchUid) return;
      const branch = await handleGetBranchDetails(branchUid);
      if (branch) {
        setActiveBranch(branch);
      } else {
        setActiveBranch(null);
      }
    };

    getBranch();
  }, [branchUid, activeBranch, setActiveBranch]);

  const shortenNameWithEllipsis = (name: string) => {
    if (name.length > 30) {
      return name.substring(0, 30) + "...";
    } else {
      return name;
    }
  };

  if (menuNotFound || (!menuLoading && !menu)) {
    return (
      <VStack
        w="100%"
        align="center"
        justify="center"
        color="gray.400"
        spacing={4}
        p={2}
      >
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Menu not found</Text>
        <ChakraLink as={RouterLink} to="/search" replace={true}>
          Go back to search
        </ChakraLink>
      </VStack>
    );
  }

  if (menuLoading) {
    return (
      <VStack align="flex-start" w="100%" pb={8}>
        <VStack
          align="flex-start"
          w="100%"
          flex={1}
          m="0 auto"
          mt="20px"
          maxW={`${theme.breakpoints.md}`}
          spacing={4}
          px={4}
        >
          {activeBranch && activeBranch.uid === branchUid && (
            <>
              <ChakraLink as={RouterLink} to="/search">
                <HStack color="gray.400" ms="10px">
                  <Icon as={HiArrowLeft} fontSize="1em" />
                  <Text fontSize="1em">Back to search</Text>
                </HStack>
              </ChakraLink>

              <BranchSubheader branch={activeBranch} />
            </>
          )}

          <Stack
            direction={isMobile ? "column" : "row"}
            w="100%"
            align="start"
            justify="space-between"
            mt={activeBranch ? "20px" : "0px"}
          >
            <Text color="primary.600" fontSize="1.2em" fontWeight="bold">
              {menuName}
            </Text>

            <HStack>
              <ItemsMultiSelect
                field="allergens"
                items={ALLERGENS_CUSTOMER_CONFIG}
                selectedItems={allergens}
                setSelectedItems={setAllergens}
                isDisabled={true}
              />
              <ItemsMultiSelect
                field="diets"
                items={DIETS_CONFIG}
                selectedItems={diets}
                setSelectedItems={setDiets}
                isDisabled={true}
              />
            </HStack>
          </Stack>
        </VStack>
      </VStack>
    );
  }

  if (menu) {
    const menuContent =
      menu.sections && menu.sections.length > 0 ? (
        <Tabs
          w="100%"
          align="start"
          variant="solid-rounded"
          colorScheme="orange"
        >
          <TabList ms={isMobile ? -4 : 0} overflowX="auto">
            {menu.sections.map((section) => (
              <Tab fontSize="0.8em">
                {shortenNameWithEllipsis(section.name)}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {menu.sections.map((section) => (
              <TabPanel px={0} w="100%">
                <SimpleGrid w="100%" columns={[1, 1, 2]} spacing={4}>
                  {section.items.map((item) => (
                    <MenuItemCard item={item} />
                  ))}
                </SimpleGrid>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <VStack
          w="100%"
          h="100%"
          align="center"
          justify="center"
          color="gray.400"
          spacing={4}
          p={2}
        >
          <Icon as={HiOutlineDocumentText} fontSize="3.6em" />
          <Text fontSize="1.2em">No menu items found</Text>
        </VStack>
      );

    return (
      <>
        {currentMenuItem && (
          <MenuItemOverlay
            item={currentMenuItem}
            setItem={setCurrentMenuItem}
          />
        )}
        <VStack align="flex-start" w="100%" pb={8}>
          <VStack
            align="flex-start"
            w="100%"
            flex={1}
            m="0 auto"
            mt="20px"
            maxW={`${theme.breakpoints.md}`}
            spacing={4}
            px={4}
          >
            {activeBranch && activeBranch.uid === branchUid && (
              <>
                <ChakraLink as={RouterLink} to="/search">
                  <HStack color="gray.400" ms="10px">
                    <Icon as={HiArrowLeft} fontSize="1em" />
                    <Text fontSize="1em">Back to search</Text>
                  </HStack>
                </ChakraLink>

                <BranchSubheader branch={activeBranch} />
              </>
            )}

            <Stack
              direction={isMobile ? "column" : "row"}
              w="100%"
              align="start"
              justify="space-between"
              mt={activeBranch ? "20px" : "0px"}
            >
              <Text color="primary.600" fontSize="1.2em" fontWeight="bold">
                {menu.name}
              </Text>
              <HStack>
                <ItemsMultiSelect
                  field="allergens"
                  items={ALLERGENS_CUSTOMER_CONFIG}
                  selectedItems={allergens}
                  setSelectedItems={setAllergens}
                  autoClose={true}
                />
                <ItemsMultiSelect
                  field="diets"
                  items={DIETS_CONFIG}
                  selectedItems={diets}
                  setSelectedItems={setDiets}
                  autoClose={true}
                />
              </HStack>
            </Stack>

            {menuContent}
          </VStack>
        </VStack>
      </>
    );
  }

  return <div>:/</div>;
};

export default MenuProfilePage;
