import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  Heading,
  HStack,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";

import { isEmailValid } from "../../utils/text";
import { ALLERGENS_CUSTOMER_CONFIG, DIETS_CONFIG } from "../../data/constants";
import { RegisterCustomerForm } from "../../features/registration/types/forms";
import { handleRegisterCustomer } from "../../features/registration/services/register";
import { handleGetAccount } from "../../features/authentication/services/account";
import { useAuthContext } from "../../features/authentication/context/authContext";
import ItemsSelector from "../../components/ItemsSelector";
import { useGlobalContext } from "../../context/globalContext";

interface Props {}

const RegisterCustomerPage: React.FC<Props> = () => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setAccount, setToken, setRegisterCustActive } = useAuthContext();
  const { isMobile, setModalLoading } = useGlobalContext();

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      allergens: [],
      diets: [],
    } as RegisterCustomerForm,
    validate: (values) => {},
    onSubmit: async (values) => {
      setError(null);
      setLoading(true);
      setModalLoading(true);
      const token = await handleRegisterCustomer(values, setError);
      if (token) {
        setRegisterCustActive(true);
        setToken(token);
        const newAccount = await handleGetAccount();
        if (newAccount) {
          setAccount(newAccount);
          setLoading(false);
          setModalLoading(false);
          setRegisterCustActive(false);
          return;
        }
      }

      setLoading(false);
      setModalLoading(false);
    },
  });

  useEffect(() => {
    setError(null);
    setDisabled(
      !isEmailValid(form.values.email) || form.values.password.length < 7
    );
  }, [form.values, setError, setDisabled]);

  return (
    <VStack
      flex={1}
      pb="2em"
      w="100%"
      align="center"
      justify={isMobile ? "flex-start" : "center"}
      mb={isMobile ? "40px" : "80px"}
    >
      <form autoComplete="off" style={{ width: "100%", maxWidth: "360px" }}>
        <VStack align="flex-start" spacing={2}>
          <Heading color="teal.600" size="md" mb="10px">
            Create your Allergood profile
          </Heading>

          <HStack w="100%">
            <Input
              name="firstName"
              type="text"
              placeholder="First name"
              size={isMobile ? "lg" : "md"}
              value={form.values.firstName}
              onChange={form.handleChange}
            />
            <Input
              name="lastName"
              type="text"
              placeholder="Last name"
              size={isMobile ? "lg" : "md"}
              value={form.values.lastName}
              onChange={form.handleChange}
            />
          </HStack>

          <Input
            name="email"
            type="email"
            placeholder="Email"
            size={isMobile ? "lg" : "md"}
            value={form.values.email}
            onChange={form.handleChange}
          />

          <Input
            name="password"
            type="password"
            autoComplete="off"
            placeholder="Password"
            size={isMobile ? "lg" : "md"}
            value={form.values.password}
            onChange={form.handleChange}
          />

          <VStack align="flex-start" w="100%" spacing={2} mt="10px">
            <Text fontSize="0.8em" color="gray.500">
              What allergens are you sensitive to? Select all that apply.
            </Text>
            <ItemsSelector
              field="allergens"
              items={ALLERGENS_CUSTOMER_CONFIG}
              selectedItems={form.values.allergens}
              setItemsField={form.setFieldValue}
            />
          </VStack>
          <VStack align="flex-start" w="100%" spacing={2} mt="10px">
            <Text fontSize="0.8em" color="gray.500">
              What are your eating preferences? Select all that apply.
            </Text>
            <ItemsSelector
              field="diets"
              items={DIETS_CONFIG}
              selectedItems={form.values.diets}
              setItemsField={form.setFieldValue}
            />
          </VStack>
          <Button
            colorScheme="teal"
            w="100%"
            mt="10px"
            isDisabled={disabled || loading}
            isLoading={loading}
            onClick={() => {
              form.handleSubmit();
            }}
          >
            Complete sign up
          </Button>

          <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
            <Fade in={error ? true : false}>
              <Box w="100%" color="red.700" fontSize="0.8em" textAlign="end">
                {error}
              </Box>
            </Fade>
          </Flex>

          <VStack
            align="flex-start"
            fontSize={isMobile ? "0.9em" : "0.8em"}
            mt="-4px"
          >
            <HStack>
              <Text color="gray.600">Looking to sign up as a business?</Text>
              <ChakraLink
                color="teal.600"
                fontWeight="bold"
                as={RouterLink}
                to="/get-started"
                replace
              >
                Get started
              </ChakraLink>
            </HStack>
            <HStack>
              <Text color="gray.600">Already have an account?</Text>
              <ChakraLink
                color="teal.600"
                fontWeight="bold"
                as={RouterLink}
                to="/login"
                replace
              >
                Log in
              </ChakraLink>
            </HStack>
          </VStack>
        </VStack>
      </form>
    </VStack>
  );
};

export default RegisterCustomerPage;
