import React, { createContext, useCallback, useContext } from "react";

import { AuthContextState, Account, Token } from "../types/state";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
  ACCOUNT_KEY,
  LOGIN_FLOW_ACTIVE,
  REGISTER_BIZ_FLOW_ACTIVE,
  REGISTER_CUST_FLOW_ACTIVE,
  TOKEN_KEY,
} from "../../../data/constants";

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState
);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useLocalStorage<Token>(TOKEN_KEY, null);
  const [account, setAccount] = useLocalStorage<Account>(ACCOUNT_KEY, null);
  // user is still in login flow
  const [loginActive, setLoginActive] = useLocalStorage(
    LOGIN_FLOW_ACTIVE,
    false
  );
  // user is still in registration business flow
  const [registerBizActive, setRegisterBizActive] = useLocalStorage(
    REGISTER_BIZ_FLOW_ACTIVE,
    false
  );
  // user is still in registration customer flow
  const [registerCustActive, setRegisterCustActive] = useLocalStorage(
    REGISTER_CUST_FLOW_ACTIVE,
    false
  );

  if (token) {
    if (typeof token.datetimeAccessCreated === "string")
      token.datetimeAccessCreated = new Date(token.datetimeAccessCreated);
    if (typeof token.datetimeRefreshCreated === "string")
      token.datetimeRefreshCreated = new Date(token.datetimeRefreshCreated);
  }

  const clearAuth = useCallback(() => {
    setToken(null);
    setAccount(null);
  }, [setToken, setAccount]);

  const value = {
    token,
    setToken,
    account,
    setAccount,
    clearAuth,
    loginActive,
    setLoginActive,
    registerBizActive,
    setRegisterBizActive,
    registerCustActive,
    setRegisterCustActive,
  } as AuthContextState;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
