import React from "react";
import {
  HStack,
  Tabs,
  TabIndicator,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  useTheme,
  Icon,
} from "@chakra-ui/react";
import {
  HiOutlineUser,
  // HiOutlineCog6Tooth,
  HiOutlineLockClosed,
} from "react-icons/hi2";

import { useGlobalContext } from "../../../context/globalContext";
import CustomerProfilePanel from "./ProfilePanel";
import CustomerSecurityPanel from "./SecurityPanel";

interface Props {}

const CustomerSettingsPage: React.FC<Props> = () => {
  const theme = useTheme();
  const { isMobile } = useGlobalContext();

  return (
    <VStack w="100%" align="center" justify="center" px={2} py={4}>
      <VStack
        align="flex-start"
        w="100%"
        flex={1}
        m="0 auto"
        maxW={`${theme.breakpoints.md}`}
        spacing={isMobile ? 3 : 8}
        px={isMobile ? 1 : 8}
      >
        <Text color="teal.600" fontSize="1.2em" fontWeight="bold">
          Account settings
        </Text>
        <Tabs
          align="start"
          w="100%"
          orientation={isMobile ? "horizontal" : "vertical"}
          variant="unstyled"
        >
          <TabList color="gray.600">
            <Tab>
              <HStack w="100%" justify="flex-start">
                <Icon as={HiOutlineUser} fontSize="1.2em" />
                <Text>Profile</Text>
              </HStack>
            </Tab>
            {/*
            <Tab>
              <HStack w="100%" justify="flex-start">
                {!isMobile && <Icon as={HiOutlineCog6Tooth} fontSize="1.2em" />}
                <Text>Preferences</Text>
              </HStack>
            </Tab>
            */}
            <Tab>
              <HStack w="100%" justify="flex-start">
                <Icon as={HiOutlineLockClosed} fontSize="1.2em" />
                <Text>Security</Text>
              </HStack>
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            h="2px"
            w="2px"
            bg="teal.500"
            borderRadius="1px"
          />
          <TabPanels
            ms={isMobile ? "0px" : "20px"}
            mt={isMobile ? "0px" : "0px"}
            w="100%"
            border={isMobile ? "none" : "1px solid"}
            borderColor="gray.200"
            borderRadius="md"
          >
            <TabPanel w="100%" px={isMobile ? 0 : 4}>
              <CustomerProfilePanel />
            </TabPanel>
            {/* 
            <TabPanel w="100%" px={isMobile ? 0 : 4}>
              <Text>preferences</Text>
            </TabPanel>
            */}
            <TabPanel w="100%" px={isMobile ? 0 : 4}>
              <CustomerSecurityPanel />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  );
};

export default CustomerSettingsPage;
