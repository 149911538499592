import React from "react";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HiChevronDown } from "react-icons/hi2";

import { City } from "../features/customer/types/state";
import { useGlobalContext } from "../context/globalContext";

interface CityMultiSelectProps {
  cities: City[] | null;
  selectedCities: City[] | null;
  setSelectedCities: (value: City[]) => void;
  isDisabled?: boolean;
  autoClose?: boolean;
  selectName?: string;
  width?: string;
}

const CityMultiSelect: React.FC<CityMultiSelectProps> = ({
  cities,
  selectedCities,
  setSelectedCities,
  selectName,
  isDisabled = false,
  autoClose = false,
  width = "auto",
}) => {
  const { isMobile } = useGlobalContext();

  const toggleSelected = (city: City) => {
    if (selectedCities && selectedCities.includes(city)) {
      setSelectedCities(selectedCities.filter((c) => c.label !== city.label));
    } else {
      if (selectedCities) {
        setSelectedCities([...selectedCities, city]);
      } else {
        setSelectedCities([city]);
      }
    }
  };

  let name = "Locations";
  let color = "gray";

  return (
    <Menu>
      <MenuButton
        as={Button}
        w={width}
        isDisabled={isDisabled}
        color="gray.600"
        fontWeight="normal"
        variant="outline"
        size={isMobile ? "md" : "md"}
        rightIcon={<HiChevronDown />}
        display="flex"
      >
        <Flex align="center">
          <Box>{selectName ? selectName : name}</Box>
          <Box ms="5px" color="gray.400" fontSize="0.8em">
            {selectedCities &&
              selectedCities.length > 0 &&
              `(${selectedCities.length})`}
          </Box>
        </Flex>
      </MenuButton>
      <MenuList>
        {cities &&
          cities.map((city) => (
            <MenuItem
              mb="2px"
              minH={isMobile ? "24px" : "28px"}
              closeOnSelect={autoClose}
              bg={
                selectedCities && selectedCities.includes(city)
                  ? `${color}.100`
                  : "white"
              }
              onClick={() => {
                toggleSelected(city);
              }}
            >
              <span>{city.label}</span>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default CityMultiSelect;
