import React, { useRef } from "react";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import MenuForm from "../../features/business/components/MenuForm";
import { useGlobalContext } from "../../context/globalContext";
import { MenuFormRef } from "../../types/state";

interface Props {}

const BusinessMenuNewPage: React.FC<Props> = () => {
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isMobile } = useGlobalContext();
  const menuFormRef = useRef<MenuFormRef>(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    menuFormRef.current?.submitForm();
  };

  return (
    <VStack align="flex-start" justify="flex-start" w="100%" maxW="1200px">
      <HStack w="100%" align="flex-start" mb={isMobile ? "10px" : "20px"}>
        <Text
          color="teal.600"
          fontSize="1.2em"
          fontWeight="normal"
          mb="20px"
          cursor="pointer"
          onClick={() => {
            navigate("/business/menus");
          }}
        >
          Menus {"→"}
        </Text>
        <Text color="teal.600" fontSize="1.2em" fontWeight="bold" mb="20px">
          New menu
        </Text>
      </HStack>
      <VStack w="100%">
        <MenuForm
          ref={menuFormRef}
          setDisabled={setDisabled}
          setLoading={setLoading}
        />
      </VStack>
      <VStack mt="10px" w="100%" align="flex-end">
        <Text color="gray.500" fontSize="0.8em">
          After you create a menu, you will be able to add menu items.
        </Text>
        <Button
          colorScheme="teal"
          isDisabled={disabled}
          isLoading={loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Create menu
        </Button>
      </VStack>
    </VStack>
  );
};

export default BusinessMenuNewPage;
