import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet } from "react-router-dom";

import { useAuthContext } from "../../authentication/context/authContext";
import {
  City,
  RestaurantBranch,
  RestaurantMenuItem,
  SearchContextState,
  RestaurantSearchParams,
} from "../types/state";
import { handleSearchBranches, handleGetCities } from "../services/search";
import useLocalStorage from "../../../hooks/useLocalStorage";

export const SearchContext = createContext<SearchContextState>(
  {} as SearchContextState
);

interface Props {}

export const SearchProvider: React.FC<Props> = () => {
  const { account } = useAuthContext();
  console.log(account);
  const [keywords, setKeywords] = useState<string>("");
  const [allergens, setAllergens] = useState<string[]>(
    account?.allergens || []
  );
  // shortcuts for various allergens (vegetarian = no meat)
  const [diets, setDiets] = useState<string[]>(account?.diets || []);

  const [allCities, setAllCities] = useState<City[] | null>(null);
  const [selectedCities, setSelectedCities] = useState<City[] | null>([]);

  const [matchPercent, setMatchPercent] = useState<number>(20);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [restaurants, setRestaurants] = useState<RestaurantBranch[] | null>(
    null
  );
  const [currentBranch, setCurrentBranch] =
    useLocalStorage<RestaurantBranch | null>("currentBranch", null);

  const [currentMenuItem, setCurrentMenuItem] =
    useState<RestaurantMenuItem | null>(null);

  const onUpdateCities = useCallback(async () => {
    const newCities = await handleGetCities();
    if (newCities !== null) {
      setAllCities(newCities);
    }
  }, [setAllCities]);

  useEffect(() => {
    onUpdateCities();
  }, [onUpdateCities]);

  // on update search, update restaurants
  const onUpdateSearch = useCallback(
    async (params: RestaurantSearchParams) => {
      setSearchLoading(true);
      const newBranches = await handleSearchBranches(params);
      setSearchLoading(false);
      if (newBranches) {
        setRestaurants(newBranches);
      }
    },
    [setRestaurants]
  );

  // on first load, update search with account preferences
  useEffect(() => {
    if (firstLoad) {
      onUpdateSearch({
        keywords,
        allergens,
        diets,
        matchPercent,
        cities: selectedCities,
      });
      setFirstLoad(false);
    }
  }, [
    keywords,
    allergens,
    diets,
    selectedCities,
    matchPercent,
    firstLoad,
    onUpdateSearch,
  ]);

  const value = {
    keywords,
    setKeywords,
    allergens,
    setAllergens,
    diets,
    setDiets,
    allCities,
    setAllCities,
    selectedCities,
    setSelectedCities,
    matchPercent,
    setMatchPercent,
    searchLoading,
    setSearchLoading,
    firstLoad,
    setFirstLoad,
    restaurants,
    setRestaurants,
    currentBranch,
    setCurrentBranch,
    currentMenuItem,
    setCurrentMenuItem,
    onUpdateSearch,
  } as SearchContextState;

  return (
    <SearchContext.Provider value={value}>
      <Outlet />
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  return useContext(SearchContext);
};
