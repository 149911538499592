import React, { useEffect, useRef } from "react";
import {
  Button,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetMenuDetails } from "../../features/business/services/get";
import { MenuDetails } from "../../features/business/types/state";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

import { MenuFormRef } from "../../types/state";
import MenuForm from "../../features/business/components/MenuForm";
import MenuItemList from "../../features/business/components/MenuItemList";
import MenuItemForm from "../../features/business/components/MenuItemForm";
import { useGlobalContext } from "../../context/globalContext";
import { useBusinessContext } from "../../features/business/context/businessContext";

interface Props {}

const BusinessMenuEditPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const { isMobile } = useGlobalContext();
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateloading] = React.useState<boolean>(false);
  const { currentMenuItem, showMenuItemForm } = useBusinessContext();
  const [menuNotFound, setMenuNotFound] = React.useState<boolean>(false);
  const menuFormRef = useRef<MenuFormRef>(null);
  const [currentMenu, setCurrentMenu] = React.useState<MenuDetails | null>(
    null
  );

  const handleSubmit = () => {
    menuFormRef.current?.submitForm();
  };

  useEffect(() => {
    if (currentMenu) return;
    const getMenu = async () => {
      if (!uid) return;
      setLoading(true);
      const menu = await handleGetMenuDetails(uid);
      setLoading(false);
      if (menu) {
        setCurrentMenu(menu);
      } else {
        setMenuNotFound(true);
      }
    };

    getMenu();
  }, [currentMenu, uid]);

  let content;
  if (menuNotFound || (!loading && !currentMenu)) {
    content = (
      <VStack
        w="100%"
        h="100%"
        align="center"
        justify="center"
        color="gray.400"
        spacing={4}
        p={2}
      >
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Menu not found</Text>
      </VStack>
    );
  } else if (loading || !currentMenu) {
    content = (
      <VStack w="100%" h="100%" align="center" justify="center" p={2}>
        <Spinner size="lg" color="teal" />
      </VStack>
    );
  } else if (currentMenu) {
    content = (
      <>
        <MenuForm
          ref={menuFormRef}
          menu={currentMenu ? currentMenu : undefined}
          setDisabled={setDisabled}
          setLoading={setUpdateloading}
        />
        <MenuItemList menu={currentMenu} />
      </>
    );
  } else {
    content = (
      <VStack
        w="100%"
        h="100%"
        align="center"
        justify="center"
        color="gray.400"
        spacing={4}
        p={2}
      >
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Something went wrong</Text>
      </VStack>
    );
  }

  return (
    <>
      {showMenuItemForm && uid && (
        <MenuItemForm menuUid={uid} item={currentMenuItem} />
      )}
      <VStack align="flex-start" w="100%" maxW="1200px">
        <HStack
          w="100%"
          align="flex-start"
          justify="space-between"
          mb={isMobile ? "10px" : "20px"}
        >
          <HStack wrap="wrap">
            <Text
              color="teal.600"
              fontSize="1.2em"
              fontWeight="normal"
              mb="20px"
              cursor="pointer"
              onClick={() => {
                navigate("/business/menus");
              }}
            >
              Menus {"→"}
            </Text>
            <Text color="teal.600" fontSize="1.2em" fontWeight="bold" mb="20px">
              Edit menu
            </Text>
          </HStack>
          {currentMenu && (
            <Stack direction={["column", "row", "row"]}>
              <Button colorScheme="gray" onClick={() => {}}>
                Get QR code
              </Button>
              <Button
                colorScheme="teal"
                isDisabled={disabled}
                isLoading={updateLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Update menu
              </Button>
            </Stack>
          )}
        </HStack>
        <VStack w="100%" h="100%">
          {content}
        </VStack>
      </VStack>
    </>
  );
};

export default BusinessMenuEditPage;
