import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HiChevronDown } from "react-icons/hi2";

import { ItemConfig } from "../types/state";
import { useGlobalContext } from "../context/globalContext";

interface ItemsMultiSelectProps {
  field: "allergens" | "diets" | "cities";
  items: ItemConfig[];
  selectedItems: string[];
  setSelectedItems: (value: string[]) => void;
  isDisabled?: boolean;
  autoClose?: boolean;
  selectName?: string;
  width?: string;
}

const ItemsMultiSelect: React.FC<ItemsMultiSelectProps> = ({
  field,
  items,
  selectedItems,
  setSelectedItems,
  selectName,
  isDisabled = false,
  autoClose = false,
  width = "auto",
}) => {
  const { isMobile } = useGlobalContext();

  const toggleSelected = (allergen: string) => {
    if (selectedItems.includes(allergen)) {
      setSelectedItems(selectedItems.filter((name) => name !== allergen));
    } else {
      setSelectedItems([...selectedItems, allergen]);
    }
  };

  let name: string;
  let color: string;
  switch (field) {
    case "allergens":
      name = "Allergens";
      color = "orange";
      break;
    case "diets":
      name = "Preferences";
      color = "green";
      break;
    case "cities":
      name = "Locations";
      color = "gray";
      break;
    default:
      name = "Allergens";
      color = "orange";
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        w={width}
        isDisabled={isDisabled}
        color="gray.600"
        fontWeight="normal"
        variant="outline"
        size={isMobile ? "md" : "md"}
        rightIcon={<HiChevronDown />}
        display="flex"
      >
        <Flex align="center">
          <Box>{selectName ? selectName : name}</Box>
          <Box ms="5px" color="gray.400" fontSize="0.8em">
            ({selectedItems.length})
          </Box>
        </Flex>
      </MenuButton>
      <MenuList>
        {items.map((item) => (
          <MenuItem
            mb="2px"
            minH={isMobile ? "24px" : "28px"}
            closeOnSelect={autoClose}
            bg={selectedItems.includes(item.name) ? `${color}.100` : "white"}
            onClick={() => {
              toggleSelected(item.name);
            }}
          >
            {item.icon && (
              <Image
                boxSize={isMobile ? "24px" : "28px"}
                borderRadius="full"
                src={item.icon}
                alt={item.name}
                mr="12px"
              />
            )}
            <span>{item.label}</span>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ItemsMultiSelect;
