import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { useAuthContext } from "../../authentication/context/authContext";
import { BusinessContextState, MenuItem, MenuItemUpdate } from "../types/state";
import { RestaurantBranchOverview, MenuOverview, MetricsResults } from "../types/state";
import { handleGetBranches, handleGetMenus, handleGetMetrics } from "../services/get";

export const BusinessContext = createContext<BusinessContextState>({} as BusinessContextState);

interface Props {
  children: React.ReactNode;
}

export const BusinessProvider: React.FC<Props> = ({ children }) => {
  const { account, token } = useAuthContext();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [menus, setMenus] = useState<MenuOverview[]>([]);
  const [branches, setBranches] = useState<RestaurantBranchOverview[]>([]);
  const [metrics, setMetrics] = useState<MetricsResults | null>(null);
  const [loadingMenus, setLoadingMenus] = useState<boolean>(true);
  const [loadingBranches, setLoadingBranches] = useState<boolean>(true);
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(true);
  const [currentMenuItem, setCurrentMenuItem] = useState<MenuItem | null>(null);
  const [showMenuItemForm, setShowMenuItemForm] = useState<boolean>(false);
  const [menuItemUpdates, setMenuItemUpdates] = useState<MenuItemUpdate[] | null>(null);

  const onUpdateMetrics = useCallback(async () => {
    setLoadingMetrics(true);
    const newMetrics = await handleGetMetrics();
    setLoadingMetrics(false);
    setMetrics(newMetrics);
  }, []);

  const onUpdateBranches = useCallback(async () => {
    setLoadingBranches(true);
    const newBranches = await handleGetBranches();
    setLoadingBranches(false);
    setBranches(newBranches);
  }, []);

  const onUpdateMenus = useCallback(async () => {
    setLoadingMenus(true);
    const newMenus = await handleGetMenus();
    setLoadingMenus(false);
    setMenus(newMenus);
  }, []);

  useEffect(() => {
    if (!token || account?.userType !== "business") return;
    onUpdateBranches();
    onUpdateMenus();
    onUpdateMetrics();
  }, [onUpdateBranches, onUpdateMenus, onUpdateMetrics, token, account]);

  const value = {
    loadingMenus,
    loadingBranches,
    loadingMetrics,
    branches,
    menus,
    metrics,
    currentMenuItem,
    setCurrentMenuItem,
    sidebarOpen,
    setSidebarOpen,
    showMenuItemForm,
    setShowMenuItemForm,
    menuItemUpdates,
    setMenuItemUpdates,
    onUpdateMenus,
  } as BusinessContextState;

  return <BusinessContext.Provider value={value}>{children}</BusinessContext.Provider>;
};

export const useBusinessContext = () => {
  return useContext(BusinessContext);
};
