import { PublicAPI } from "../../../api";
import { ACCESS_TOKEN_EXPIRES, REFRESH_TOKEN_EXPIRES } from "../../../data/constants";
import { Token } from "../../authentication/types/state";
import { CompleteCustomerForm, RegisterBusinessForm, RegisterCustomerForm } from "../types/forms";

export const handleCompleteBusinessRegistration = async (
  formData: CompleteCustomerForm,
  setError: (error: string) => void
): Promise<Token | undefined> => {
  const { uid, password, firstName, lastName, organizationName } = formData;

  try {
    const res = await PublicAPI.post("account/complete_business_registration/", {
      uid,
      password,
      first_name: firstName,
      last_name: lastName,
      organization_name: organizationName,
    });
    if (res.status === 200) {
      return {
        email: res.data.email,
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        datetimeAccessCreated: new Date(),
        datetimeRefreshCreated: new Date(),
        accessExpiresIn: ACCESS_TOKEN_EXPIRES,
        refreshExpiresIn: REFRESH_TOKEN_EXPIRES,
        app: "allergood",
      } as Token;
    }
  } catch (error) {
    console.log(error);
    setError("Failed to complete business registration.");
  }
};

export const handleCompleteCustomerRegistration = async (
  formData: CompleteCustomerForm,
  setError: (error: string) => void
): Promise<Token | undefined> => {
  const { uid, password, firstName, lastName, allergens, diets } = formData;

  try {
    const res = await PublicAPI.post("account/complete_customer_registration/", {
      uid,
      password,
      first_name: firstName,
      last_name: lastName,
      allergens,
      diets,
    });
    if (res.status === 200) {
      return {
        email: res.data.email,
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        datetimeAccessCreated: new Date(),
        datetimeRefreshCreated: new Date(),
        accessExpiresIn: ACCESS_TOKEN_EXPIRES,
        refreshExpiresIn: REFRESH_TOKEN_EXPIRES,
        app: "allergood",
      } as Token;
    }
  } catch (error) {
    console.log(error);
    setError("Failed to complete customer registration.");
  }
};

export const handleRegisterBusiness = async (
  formData: RegisterBusinessForm,
  setError: (error: string) => void
): Promise<Token | undefined> => {
  const { email, password, businessName, firstName, lastName, phoneNumber } = formData;
  try {
    const res = await PublicAPI.post("account/register_business/", {
      email,
      password,
      business_name: businessName,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
    });
    if (res.status === 201) {
      return {
        email,
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        datetimeAccessCreated: new Date(),
        datetimeRefreshCreated: new Date(),
        accessExpiresIn: ACCESS_TOKEN_EXPIRES,
        refreshExpiresIn: REFRESH_TOKEN_EXPIRES,
        app: "allergood",
      } as Token;
    }
  } catch (error) {
    // if error.code === "ERR_NETWORK" then show some other message
    console.log(error);
    setError("An account with this email might already exist.");
  }
};

export const handleRegisterCustomer = async (
  formData: RegisterCustomerForm,
  setError: (error: string) => void
): Promise<Token | undefined> => {
  const { email, password, firstName, lastName, allergens, diets } = formData;
  try {
    const res = await PublicAPI.post("account/register_customer/", {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      allergens,
      diets,
    });
    if (res.status === 201) {
      return {
        email,
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        datetimeAccessCreated: new Date(),
        datetimeRefreshCreated: new Date(),
        accessExpiresIn: ACCESS_TOKEN_EXPIRES,
        refreshExpiresIn: REFRESH_TOKEN_EXPIRES,
        app: "allergood",
      } as Token;
    }
  } catch (error) {
    // if error.code === "ERR_NETWORK" then show some other message
    setError("An account with this email might already exist.");
  }
};
