import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import { MenuFormRef } from "../../../types/state";
import { MenuDetails } from "../types/state";
import { ExtendedMenuDetails } from "../types/forms";
import { useGlobalContext } from "../../../context/globalContext";
import { handleCreateMenu } from "../services/create";
import { handleUpdateMenu, handleUpdateMenuItemsBatch } from "../services/update";
import { useBusinessContext } from "../context/businessContext";

interface Props {
  menu?: MenuDetails;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuForm = forwardRef<MenuFormRef, Props>((props, ref) => {
  const [initialize, setInitialized] = useState(false);
  const { isMobileOrTablet, setModalLoading } = useGlobalContext();
  const { menuItemUpdates } = useBusinessContext();
  const [photo, setPhoto] = useState<string | ArrayBuffer | null>(null);
  const navigate = useNavigate();

  const { menu, setDisabled, setLoading } = props;

  const form = useFormik({
    initialValues: {
      photoFile: null,
      photoHref: "",
      name: "",
      allergenMessage: "",
      isPublished: true,
    } as ExtendedMenuDetails,
    validate: () => {},
    onSubmit: async (values) => {
      console.log("MenuForm.onSubmit: values", values);
      setDisabled(true);
      setLoading(true);
      setModalLoading(true);
      if (menu && menu.uid) {
        console.log(menuItemUpdates);
        const updateSuccess = await handleUpdateMenu(menu.uid, values);
        console.log("updateSuccess", updateSuccess);

        if (updateSuccess) {
          if (menuItemUpdates && menuItemUpdates.length > 0) {
            const batchSuccess = await handleUpdateMenuItemsBatch(menuItemUpdates);
            console.log("batchSuccess", batchSuccess);
          }

          // this will clear menu item updates
          window.location.reload();
        }
      } else {
        const menuUid = await handleCreateMenu(values);
        console.log("menuUid", menuUid);
        if (menuUid) {
          navigate(`/business/menus/${menuUid}`, { replace: true });
        }
      }

      setModalLoading(false);
      setLoading(false);
      setDisabled(false);
    },
  });

  useImperativeHandle(ref, () => ({
    async submitForm() {
      await form.submitForm();
    },
  }));

  useEffect(() => {
    if (!initialize && menu) {
      form.setFieldValue("photoHref", menu.photoHref);
      form.setFieldValue("name", menu.name);
      form.setFieldValue("allergenMessage", menu.allergenMessage);
      form.setFieldValue("isPublished", menu.isPublished);
      setInitialized(true);
    }
  }, [form, initialize, menu]);

  useEffect(() => {
    if (form.values.name === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [form.values.name, setDisabled]);

  let photoContent;
  if (photo) {
    photoContent = <Image src={photo as string} alt="menu photo" objectFit="cover" w="100%" />;
  } else if (form.values.photoHref) {
    photoContent = (
      <Image src={form.values.photoHref} alt="menu photo" objectFit="cover" w="100%" />
    );
  } else {
    photoContent = (
      <Flex w="100%" h="100%" align="center" justify="center" bg="gray.200">
        <Icon as={HiOutlinePlus} fontSize="1.1em" color="gray.500" />
        <Text color="gray.500" ms="4px">
          Add photo
        </Text>
      </Flex>
    );
  }

  return (
    <VStack align="flex-start" w="100%">
      <Stack w="100%" direction={["column", "column", "row"]} spacing={4}>
        <VStack minW={isMobileOrTablet ? "100%" : "auto"} align="flex-start">
          <Text fontSize="0.8em" color="gray.500">
            Menu photo
          </Text>
          <Flex
            cursor="pointer"
            borderRadius="md"
            overflow="hidden"
            bg="gray.100"
            w={isMobileOrTablet ? "100%" : "320px"}
            minH="240px"
            maxH="320px"
            onClick={() => {
              const input = document.getElementById("imageInput");
              input?.click();
            }}
          >
            {photoContent}

            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    if (event.target?.result) {
                      setPhoto(event.target.result);
                      form.setFieldValue("photoFile", file);
                    }
                  };
                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
          </Flex>
        </VStack>

        <VStack w="100%">
          <Stack w="100%" direction={["row", "row", "row"]} justify="space-between">
            <Flex>
              <FormControl isRequired={true}>
                <FormLabel fontSize="0.8em" color="gray.500">
                  Menu name
                </FormLabel>
                <Input
                  name="name"
                  type="text"
                  maxW="280px"
                  placeholder="Menu name"
                  value={form.values.name}
                  onChange={form.handleChange}
                />
              </FormControl>
            </Flex>

            <Flex>
              <FormControl isRequired={true}>
                <FormLabel fontSize="0.8em" color="gray.500">
                  Is published
                </FormLabel>
                <Checkbox
                  name="isPublished"
                  size="lg"
                  isChecked={form.values.isPublished}
                  onChange={(e) => {
                    form.setFieldValue("isPublished", e.target.checked);
                  }}
                />
              </FormControl>
            </Flex>
          </Stack>

          <FormControl>
            <FormLabel fontSize="0.8em" color="gray.500">
              Allergen message
            </FormLabel>
            <Textarea
              name="allergenMessage"
              h="166px"
              value={form.values.allergenMessage}
              onChange={form.handleChange}
            />
          </FormControl>
        </VStack>
      </Stack>
    </VStack>
  );
});

export default MenuForm;
