import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  HiOutlineUser,
  HiOutlineArrowRightOnRectangle,
  HiBars3,
} from "react-icons/hi2";

import Logo from "../../../assets/images/logo.png";
import { useAuthContext } from "../../authentication/context/authContext";
import { useGlobalContext } from "../../../context/globalContext";
import { useBusinessContext } from "../context/businessContext";

interface Props {}

const HeaderBusiness: React.FC<Props> = () => {
  const { clearAuth } = useAuthContext();
  const { isMobileOrTablet, setModalLoading } = useGlobalContext();
  const { sidebarOpen, setSidebarOpen } = useBusinessContext();

  return (
    <Flex
      position="fixed"
      top="0"
      align="center"
      justify="space-between"
      h="48px"
      w="100%"
      zIndex={99}
      shadow="md"
      bg="white"
      px={isMobileOrTablet ? 2 : 4}
    >
      <IconButton
        visibility={isMobileOrTablet ? "visible" : "hidden"}
        aria-label="expand sidebar"
        icon={<HiBars3 />}
        variant="ghost"
        color="gray.600"
        fontSize="1.6em"
        onClick={() => {
          setSidebarOpen(!sidebarOpen);
        }}
      />
      <Box h="80%">
        <Image h="100%" src={Logo} alt="logo" />
      </Box>
      <Flex align="center" h="100%">
        <Menu gutter={0}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HiOutlineUser />}
            variant="ghost"
            color="gray.600"
            fontSize={isMobileOrTablet ? "1.8em" : "1.6em"}
            _active={{ bg: "transparent" }}
          />
          <MenuList>
            <MenuItem
              color="gray.600"
              icon={
                <HiOutlineArrowRightOnRectangle
                  fontSize={isMobileOrTablet ? "1.6em" : "1.4em"}
                />
              }
              onClick={() => {
                setModalLoading(true);
                setTimeout(() => {
                  clearAuth();
                  // vv this will not trigger token-based redirect to /login
                  localStorage.clear();
                  setModalLoading(false);
                }, 1200);
              }}
            >
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default HeaderBusiness;
