import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Heading,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";

import { isEmailValid } from "../utils/text";
import { LoginForm } from "../features/authentication/types/forms";
import { useAuthContext } from "../features/authentication/context/authContext";
import { handleLogin } from "../features/authentication/services/login";
import { handleGetAccount } from "../features/authentication/services/account";
import { useGlobalContext } from "../context/globalContext";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setAccount, setToken, setLoginActive } = useAuthContext();
  const { isMobile, setModalLoading } = useGlobalContext();

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    } as LoginForm,
    validate: (values) => {},
    onSubmit: async (values) => {
      setError(null);
      setLoading(true);
      setModalLoading(true);

      const token = await handleLogin(values, setError);
      if (token) {
        setLoginActive(true);
        setToken(token);
        const newAccount = await handleGetAccount();
        if (newAccount) {
          setAccount(newAccount);
          setLoading(false);
          setModalLoading(false);
          setLoginActive(false); // this will trigger the redirect to /
          return;
        }
      }

      setLoading(false);
      setModalLoading(false);
    },
  });

  useEffect(() => {
    setError(null);
    setDisabled(
      !isEmailValid(form.values.email) || form.values.password.length < 7
    );
  }, [form.values, setError, setDisabled]);

  return (
    <VStack
      flex={1}
      w="100%"
      align="center"
      justify={isMobile ? "flex-start" : "center"}
      mb={isMobile ? "40px" : "80px"}
    >
      <form style={{ width: "100%", maxWidth: "360px" }}>
        <VStack align="flex-start" spacing={2}>
          <Heading color="teal.600" size="md" mb="10px">
            Log in
          </Heading>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            size={isMobile ? "lg" : "md"}
            value={form.values.email}
            onChange={form.handleChange}
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
            size={isMobile ? "lg" : "md"}
            value={form.values.password}
            onChange={form.handleChange}
          />
          <Button
            colorScheme="teal"
            w="100%"
            mt="10px"
            size={isMobile ? "lg" : "md"}
            isDisabled={disabled || loading}
            isLoading={loading}
            onClick={() => {
              form.handleSubmit();
            }}
          >
            Log in
          </Button>
          <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
            <Fade in={error ? true : false}>
              <Box w="100%" color="red.700" fontSize="0.8em" textAlign="end">
                {error}
              </Box>
            </Fade>
          </Flex>
          <VStack
            align="flex-start"
            fontSize={isMobile ? "0.9em" : "0.8em"}
            mt="-4px"
          >
            <HStack>
              <Text color="gray.600">Forgot your password?</Text>
              <ChakraLink
                color="teal.600"
                fontWeight="bold"
                as={RouterLink}
                to="/reset-password"
                replace
              >
                Reset password
              </ChakraLink>
            </HStack>
            <HStack>
              <Text color="gray.600">Don't have an account?</Text>
              <ChakraLink
                color="teal.600"
                fontWeight="bold"
                as={RouterLink}
                to="/register"
                replace
              >
                Sign up
              </ChakraLink>
            </HStack>
          </VStack>
        </VStack>
      </form>
    </VStack>
  );
};

export default LoginPage;
