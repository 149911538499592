import React, { useRef } from "react";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import BranchForm from "../../features/business/components/BranchForm";
import { useGlobalContext } from "../../context/globalContext";
import { BranchFormRef } from "../../types/state";

interface Props {}

const BusinessLocationNewPage: React.FC<Props> = () => {
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isMobile } = useGlobalContext();
  const branchFormRef = useRef<BranchFormRef>(null);
  const navigate = useNavigate();

  const handleSubmit = () => {
    branchFormRef.current?.submitForm();
  };

  return (
    <VStack
      align="flex-start"
      justify="flex-start"
      w="100%"
      maxW="860px"
      pb="40px"
    >
      <HStack w="100%" align="flex-start" mb={isMobile ? "10px" : "20px"}>
        <Text
          color="teal.600"
          fontSize="1.2em"
          fontWeight="normal"
          mb="20px"
          cursor="pointer"
          onClick={() => {
            navigate("/business/locations");
          }}
        >
          Locations {"→"}
        </Text>
        <Text color="teal.600" fontSize="1.2em" fontWeight="bold" mb="20px">
          New location
        </Text>
      </HStack>
      <VStack w="100%">
        <BranchForm
          ref={branchFormRef}
          setDisabled={setDisabled}
          setLoading={setLoading}
        />
      </VStack>
      <VStack mt="10px" w="100%" align="flex-end">
        <Button
          colorScheme="teal"
          isDisabled={disabled}
          isLoading={loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Create location
        </Button>
      </VStack>
    </VStack>
  );
};

export default BusinessLocationNewPage;
