import React from "react";
import {
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link as ChakraLink,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { HiOutlineMapPin, HiOutlinePhone, HiLink } from "react-icons/hi2";

import { formatPhoneNumber } from "../utils/text";
import { RestaurantBranch } from "../features/customer/types/state";
import { useGlobalContext } from "../context/globalContext";

interface Props {
  branch: RestaurantBranch;
  loading?: boolean;
}

const BranchSubheader: React.FC<Props> = ({ branch, loading }) => {
  const { isMobile } = useGlobalContext();

  console.log(branch);

  let imageContent;
  if (branch.photoHref) {
    imageContent = (
      <Flex
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        overflow="hidden"
        bg="gray.100"
        w="100%"
        maxW={["360px", "240px", "360px"]}
        h={["220px", "200px", "240px"]}
        position="relative"
      >
        <Image
          src={branch.photoHref}
          alt="branch photo"
          objectFit="cover"
          w="100%"
        />
        {branch.logoHref && (
          <Flex
            position="absolute"
            bottom="4px"
            right="4px"
            w="80px"
            h="80px"
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={branch.logoHref}
              alt="branch logo"
              objectFit="cover"
              w="100%"
            />
          </Flex>
        )}
      </Flex>
    );
  } else if (branch.logoHref) {
    imageContent = (
      <Flex
        w="240px"
        h="240px"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="full"
        overflow="hidden"
      >
        <Image
          src={branch.logoHref}
          alt="branch logo"
          objectFit="cover"
          w="100%"
        />
      </Flex>
    );
  } else {
    imageContent = <SkeletonCircle size={isMobile ? "80px" : "100px"} />;
  }

  return (
    <VStack w="100%" align="flex-start" spacing={4}>
      <Stack
        w="100%"
        direction={["column", "row", "row"]}
        align="flex-start"
        spacing={[4, 6, 8]}
      >
        {loading ? (
          <SkeletonCircle size={isMobile ? "80px" : "100px"} />
        ) : (
          imageContent
        )}

        <VStack align="flex-start">
          <Skeleton isLoaded={!loading}>
            <ChakraLink as={RouterLink} to={`/restaurants/${branch.uid}`}>
              <Heading
                fontSize={isMobile ? "1.2em" : "1.4em"}
                color="primary.600"
              >
                {branch.name}
              </Heading>
            </ChakraLink>
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            <Text fontSize={isMobile ? "1em" : "1.2em"} color="gray.600">
              {branch.cuisine}
            </Text>
          </Skeleton>
          <Skeleton isLoaded={!loading}>
            {branch.googleMapsLink ? (
              <ChakraLink href={branch.googleMapsLink} isExternal={true}>
                <HStack color="gray.600" spacing={3} mt="1">
                  <Icon as={HiOutlineMapPin} fontSize="1.2em" />
                  <Text fontSize="1em">
                    {branch.address.street}, {branch.address.city},{" "}
                    {branch.address.state}
                  </Text>
                </HStack>
              </ChakraLink>
            ) : (
              <HStack color="gray.600" spacing={3} mt="1">
                <Icon as={HiOutlineMapPin} fontSize="1.2em" />
                <Text fontSize="1em">
                  {branch.address.street}, {branch.address.city},{" "}
                  {branch.address.state}
                </Text>
              </HStack>
            )}
          </Skeleton>

          <Skeleton isLoaded={!loading}>
            {branch.phoneNumber && (
              <ChakraLink href={`tel:${branch.phoneNumber}`} isExternal={true}>
                <HStack color="gray.600" spacing={3}>
                  <Icon as={HiOutlinePhone} fontSize="1.2em" />
                  <Text fontSize="1em">
                    {formatPhoneNumber(branch.phoneNumber)}
                  </Text>
                </HStack>
              </ChakraLink>
            )}
          </Skeleton>

          <HStack color="gray.500" spacing={3} mt="10px">
            {branch.uberEatsLink && (
              <ChakraLink href={branch.uberEatsLink} isExternal={true}>
                <HStack spacing={1}>
                  <Icon as={HiLink} fontSize="1.0em" />
                  <Text fontSize="1em">Uber Eats</Text>
                </HStack>
              </ChakraLink>
            )}

            {branch.grubHubLink && (
              <ChakraLink href={branch.grubHubLink} isExternal={true}>
                <HStack spacing={1}>
                  <Icon as={HiLink} fontSize="1.0em" />
                  <Text fontSize="1em">Grubhub</Text>
                </HStack>
              </ChakraLink>
            )}

            {branch.doordashLink && (
              <ChakraLink href={branch.doordashLink} isExternal={true}>
                <HStack spacing={1}>
                  <Icon as={HiLink} fontSize="1.0em" />
                  <Text fontSize="1em">DoorDash</Text>
                </HStack>
              </ChakraLink>
            )}
          </HStack>
        </VStack>
      </Stack>
    </VStack>
  );
};

export default BranchSubheader;
