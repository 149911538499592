import React from "react";
import { HStack, Image, Text, Tooltip } from "@chakra-ui/react";

import { ItemConfig } from "../types/state";

interface Props {
  allergens: string[];
  allergensConfig: ItemConfig[];
  showLabels?: boolean;
}

const AllergensList: React.FC<Props> = ({
  allergens,
  allergensConfig,
  showLabels,
}) => {
  const spacing = showLabels ? 3 : 2;

  return (
    <HStack spacing={spacing} wrap="wrap">
      {allergens.map((allergen) => {
        const allergenConfig = allergensConfig.find(
          (config) => config.name === allergen
        );
        if (!allergenConfig || !allergenConfig.icon) return null;

        if (showLabels) {
          return (
            <HStack align="center">
              <Image
                key={allergen}
                src={allergenConfig.icon}
                alt={allergenConfig.label}
                w="1.5em"
                h="1.5em"
              />
              <Text color="gray.500" fontSize="0.9em">
                {allergenConfig.label}
              </Text>
            </HStack>
          );
        } else {
          return (
            <Tooltip
              label={allergenConfig.label}
              hasArrow
              color="teal.600"
              bg="gray.100"
            >
              <Image
                key={allergen}
                src={allergenConfig.icon}
                alt={allergenConfig.label}
                w="1.5em"
                h="1.5em"
              />
            </Tooltip>
          );
        }
      })}
    </HStack>
  );
};

export default AllergensList;
