import React, { useState } from "react";
import {
  Button,
  Icon,
  HStack,
  InputGroup,
  InputLeftElement,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { useFormik } from "formik";

import { areArraysEqual } from "../../../utils/array";
import ItemsMultiSelect from "../../../components/ItemsMultiSelect";
import CityMultiSelect from "../../../components/CitiesMultiSelect";
import RangeSliderInput from "../../../components/RangeSliderInput";
import { useSearchContext } from "../context/searchContext";
import { useGlobalContext } from "../../../context/globalContext";
import {
  ALLERGENS_CUSTOMER_CONFIG,
  DIETS_CONFIG,
} from "../../../data/constants";

interface Props {}

const RestaurantSearchBar: React.FC<Props> = () => {
  const { isMobile } = useGlobalContext();
  const theme = useTheme();
  const [disabled, setDisabled] = useState(true);

  const {
    allergens,
    setAllergens,
    diets,
    setDiets,
    allCities,
    selectedCities,
    setSelectedCities,
    matchPercent,
    setMatchPercent,
    keywords,
    setKeywords,
    onUpdateSearch,
  } = useSearchContext();

  const form = useFormik({
    initialValues: {
      keywords,
      allergens,
      diets,
      matchPercent,
      cities: selectedCities,
    },
    validate: (values) => {
      const currCities = values.cities?.map((city) => city.label);
      const oldCities = selectedCities?.map((city) => city.label);

      console.log(currCities, oldCities);

      if (
        values.keywords === keywords &&
        values.matchPercent === matchPercent &&
        areArraysEqual(values.allergens, allergens) &&
        areArraysEqual(values.diets, diets) &&
        areArraysEqual(currCities, oldCities)
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    },
    onSubmit: async (values) => {
      setKeywords(values.keywords);
      setAllergens(values.allergens);
      setDiets(values.diets);
      setSelectedCities(values.cities);
      setMatchPercent(values.matchPercent);
      onUpdateSearch({
        keywords: values.keywords,
        allergens: values.allergens,
        diets: values.diets,
        cities: values.cities,
        matchPercent: values.matchPercent,
      });
      setDisabled(true);
    },
  });

  return (
    <VStack w="100%" m="0 auto" maxW={`${theme.breakpoints.md}`} px={2}>
      <HStack wrap="wrap" w="100%">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={HiMagnifyingGlass} color="gray.400" />
          </InputLeftElement>
          <Input
            name="keywords"
            placeholder={
              isMobile
                ? "Search by keyword"
                : "Search for restaurants, locations, or cuisines"
            }
            size={isMobile ? "md" : "md"}
            value={form.values.keywords}
            onChange={form.handleChange}
          />
        </InputGroup>

        <ItemsMultiSelect
          field="allergens"
          items={ALLERGENS_CUSTOMER_CONFIG}
          selectedItems={form.values.allergens}
          setSelectedItems={(value) => form.setFieldValue("allergens", value)}
        />

        <ItemsMultiSelect
          field="diets"
          items={DIETS_CONFIG}
          selectedItems={form.values.diets}
          setSelectedItems={(value) => form.setFieldValue("diets", value)}
        />
        <RangeSliderInput
          value={form.values.matchPercent}
          setValue={(value) => form.setFieldValue("matchPercent", value)}
        />
        <CityMultiSelect
          cities={allCities}
          selectedCities={form.values.cities}
          setSelectedCities={(value) => form.setFieldValue("cities", value)}
          isDisabled={false}
          autoClose={false}
          selectName="Locations"
          width="auto"
        />
      </HStack>
      <HStack w="100%" align="flex-start" justify="space-between">
        <Text
          ms="2px"
          color="gray.500"
          fontSize={isMobile ? "0.6em" : "0.8em"}
          maxW="50%"
        >
          Filter values by default are populated with the allergens and dietary
          preferences listed in{" "}
          <ChakraLink
            as={RouterLink}
            to="/settings"
            color="gray.600"
            fontWeight="bold"
          >
            your profile
          </ChakraLink>
          .
        </Text>
        <Button
          colorScheme="teal"
          size={isMobile ? "md" : "md"}
          isDisabled={disabled}
          onClick={() => {
            form.handleSubmit();
          }}
        >
          Update search
        </Button>
      </HStack>
    </VStack>
  );
};

export default RestaurantSearchBar;
