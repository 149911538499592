import React from "react";
import { Flex, HStack, Image, Text } from "@chakra-ui/react";

import { useGlobalContext } from "../context/globalContext";
import { ItemConfig } from "../types/state";

interface ItemsSelectorProps {
  field: string;
  items: ItemConfig[];
  selectedItems: string[];
  setItemsField: (field: string, value: string[]) => void;
}

export const ItemsSelector: React.FC<ItemsSelectorProps> = ({
  field,
  items,
  selectedItems,
  setItemsField,
}) => {
  const { isMobile } = useGlobalContext();

  const toggleSelected = (itemName: string) => {
    if (selectedItems.includes(itemName)) {
      setItemsField(
        field,
        selectedItems.filter((a) => a !== itemName)
      );
    } else {
      setItemsField(field, [...selectedItems, itemName]);
    }
  };

  return (
    <HStack wrap="wrap">
      {items.map((item) => (
        <SelectorButton
          key={item.name}
          icon={item.icon}
          name={item.name}
          label={item.label}
          selected={selectedItems.includes(item.name)}
          toggleSelected={toggleSelected}
          isMobile={isMobile}
        />
      ))}
    </HStack>
  );
};

/*** Button ***/

interface ButtonProps {
  icon: string | null;
  name: string;
  label: string;
  selected: boolean;
  toggleSelected: (allergen: string) => void;
  isMobile: boolean;
}

const SelectorButton: React.FC<ButtonProps> = ({
  icon,
  name,
  label,
  selected,
  toggleSelected,
  isMobile,
}) => {
  return (
    <Flex
      px="8px"
      py="4px"
      bg={selected ? "gray.100" : "white"}
      color="gray.500"
      border="1px solid"
      borderColor={selected ? "gray.400" : "gray.200"}
      borderRadius="20px"
      align="center"
      justify="center"
      transition="all 0.2s ease-in-out"
      cursor="pointer"
      _hover={{
        bg: selected ? "gray.200" : "orange.50",
      }}
      onClick={() => toggleSelected(name)}
    >
      {icon && (
        <Image src={icon} alt={label} boxSize={isMobile ? "32px" : "28px"} />
      )}
      <Text ms="6px" fontSize={isMobile ? "1em" : "0.9em"}>
        {label}
      </Text>
    </Flex>
  );
};

export default ItemsSelector;
