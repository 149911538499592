import React from "react";
import { Flex, Image, Text, VStack, Checkbox } from "@chakra-ui/react";

import { RestaurantMenu } from "../features/customer/types/state";
import { useGlobalContext } from "../context/globalContext";

interface Props {
  menu: RestaurantMenu;
  selected: boolean;
  onClick: () => void;
}

const SelectMenuCard: React.FC<Props> = ({ menu, selected, onClick }) => {
  const { isMobile } = useGlobalContext();

  return (
    <VStack
      align="flex-start"
      w="100%"
      cursor="pointer"
      spacing={1}
      onClick={() => {
        console.log("clicked");
        onClick();
      }}
    >
      <Flex
        position="relative"
        borderRadius="md"
        overflow="hidden"
        bg="gray.100"
        w="100%"
        h={isMobile ? "200px" : "240px"}
      >
        <Checkbox
          position="absolute"
          top="10px"
          right="10px"
          size="lg"
          colorScheme="teal"
          isChecked={selected}
          pointerEvents="none"
        />

        <Image
          src={menu.photoHref}
          fallbackSrc={menu.photoHref}
          alt="menu photo"
          objectFit="cover"
          w="100%"
        />
      </Flex>
      <Flex>
        <Text fontSize="1.1em" color="gray.600">
          {menu.name}
        </Text>
      </Flex>
    </VStack>
  );
};

export default SelectMenuCard;
