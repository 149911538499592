import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import { useGlobalContext } from "../../../context/globalContext";
import { useAuthContext } from "../../../features/authentication/context/authContext";
import { handleGetAccount } from "../../../features/authentication/services/account";
import { UpdateBusinessAccountForm } from "../../../features/settings/types/forms";
import { handleUpdateBusinessAccount } from "../../../features/settings/services/account";

interface Props {}

const BusinessProfilePanel: React.FC<Props> = () => {
  const { account, setAccount } = useAuthContext();
  const { isMobile, setModalLoading } = useGlobalContext();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const form = useFormik({
    initialValues: {
      email: account?.email || "",
      firstName: account?.firstName || "",
      lastName: account?.lastName || "",
      phoneNumber: account?.phoneNumber || "",
      organizationName: account?.organization?.name || "",
    } as UpdateBusinessAccountForm,
    validate: (values) => {
      if (
        values.email === account?.email &&
        values.firstName === account?.firstName &&
        values.lastName === account?.lastName &&
        values.phoneNumber === account?.phoneNumber &&
        values.organizationName === account?.organization?.name
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    },
    onSubmit: async (values, { resetForm }) => {
      setError(null);
      setLoading(true);
      setModalLoading(true);

      const updateSuccess = await handleUpdateBusinessAccount(values, setError);
      if (updateSuccess) {
        const newAccount = await handleGetAccount();
        if (newAccount) {
          setAccount(newAccount);
          setShowSuccess(true);
          setDisabled(true);
        }
      }

      setLoading(false);
      setModalLoading(false);
    },
  });

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 10000);
    }
  }, [showSuccess]);

  return (
    <VStack align="flex-start" w="100%" h="100%">
      <Stack direction={isMobile ? "column" : "row"} w="100%">
        <FormControl>
          <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
            First name
          </FormLabel>
          <Input
            name="firstName"
            type="text"
            color="gray.600"
            placeholder="First name"
            size={isMobile ? "lg" : "md"}
            value={form.values.firstName}
            onChange={form.handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
            Last name
          </FormLabel>
          <Input
            name="lastName"
            type="text"
            color="gray.600"
            placeholder="Last name"
            size={isMobile ? "lg" : "md"}
            value={form.values.lastName}
            onChange={form.handleChange}
          />
        </FormControl>
      </Stack>
      <FormControl>
        <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
          Organization name
        </FormLabel>
        <Input
          name="organizationName"
          type="text"
          color="gray.600"
          placeholder="Organization name"
          size={isMobile ? "lg" : "md"}
          value={form.values.organizationName}
          onChange={form.handleChange}
        />
      </FormControl>
      <Stack direction={isMobile ? "column" : "row"} w="100%">
        <FormControl>
          <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
            Phone number
          </FormLabel>
          <Input
            name="phoneNumber"
            type="phone"
            color="gray.600"
            placeholder="Phone number"
            size={isMobile ? "lg" : "md"}
            value={form.values.phoneNumber}
            onChange={form.handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
            Email
          </FormLabel>
          <Input
            disabled={true}
            name="email"
            type="email"
            color="gray.600"
            placeholder="Email address"
            size={isMobile ? "lg" : "md"}
            value={form.values.email}
            onChange={form.handleChange}
          />
        </FormControl>
      </Stack>

      <HStack w="100%" align="end" justify="space-between">
        <Fade in={showSuccess ? true : false}>
          <Box color="green.500" fontSize="1em" fontWeight="bold">
            Account updated.
          </Box>
        </Fade>
        <VStack mt="10px" align="flex-end">
          <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
            <Fade in={error ? true : false}>
              <Box w="100%" color="red.600" fontSize="0.8em" fontWeight="bold" textAlign="end">
                {error}
              </Box>
            </Fade>
          </Flex>
          <Button
            size={isMobile ? "lg" : "md"}
            colorScheme="teal"
            isDisabled={disabled || loading}
            isLoading={loading}
            onClick={() => {
              form.handleSubmit();
            }}
          >
            Update profile
          </Button>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default BusinessProfilePanel;
