import React from "react";
import {
  Badge,
  Flex,
  Image,
  HStack,
  Link as ChakraLink,
  Text,
  VStack,
  Skeleton,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { RestaurantBranchOverview } from "../types/state";
import { useGlobalContext } from "../../../context/globalContext";

interface Props {
  branch: RestaurantBranchOverview;
  link: string;
}

const BranchCard: React.FC<Props> = ({ branch, link }) => {
  const { isMobile } = useGlobalContext();

  return (
    <ChakraLink as={RouterLink} to={link} w="100%">
      <VStack align="flex-start" w="100%" spacing={1}>
        <Flex
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          overflow="hidden"
          bg="gray.100"
          w="100%"
          h={isMobile ? "200px" : "240px"}
          position="relative"
        >
          {branch.photoHref && (
            <Image src={branch.photoHref} alt="branch photo" objectFit="cover" w="100%" />
          )}
          <Flex
            position="absolute"
            bottom="4px"
            right="4px"
            w="80px"
            h="80px"
            borderRadius="md"
            overflow="hidden"
            bg="gray.200"
          >
            {branch.logoHref && (
              <Image src={branch.logoHref} alt="branch logo" objectFit="cover" w="100%" />
            )}
          </Flex>
        </Flex>
        <HStack w="100%" justify="space-between">
          <Text fontSize="1.1em" color="gray.600">
            {branch.name}
          </Text>
          <Badge colorScheme={branch.isPublished ? "green" : "orange"} size="sm">
            {branch.isPublished ? "published" : "not published"}
          </Badge>
        </HStack>
      </VStack>
    </ChakraLink>
  );
};

export const BranchCardSkeleton: React.FC = () => {
  const { isMobile } = useGlobalContext();

  return (
    <VStack>
      <Skeleton borderRadius="md" w="100%" h={isMobile ? "200px" : "240px"} />
      <Skeleton w="100%" h="20px" />
    </VStack>
  );
};

export default BranchCard;
