import React, { createContext, useContext, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@chakra-ui/react";

import { GlobalContextState } from "../types/state";

export const GlobalContext = createContext<GlobalContextState>(
  {} as GlobalContextState
);

interface Props {
  children: React.ReactNode;
}

export const GlobalProvider: React.FC<Props> = ({ children }) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const theme = useTheme();
  const [isMobile] = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [isMobileOrTablet] = useMediaQuery(
    `(max-width: ${theme.breakpoints.md})`
  );

  useEffect(() => {
    const updateConnectionStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", updateConnectionStatus);
    window.addEventListener("offline", updateConnectionStatus);

    return () => {
      window.removeEventListener("online", updateConnectionStatus);
      window.removeEventListener("offline", updateConnectionStatus);
    };
  }, []);

  const value = {
    modalLoading,
    setModalLoading,
    isOnline,
    isMobile,
    isMobileOrTablet,
  } as GlobalContextState;

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
