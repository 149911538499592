import { ProtectedAPI, PublicAPI } from "../../../api";
import { UpdatePasswordForm } from "../types/forms";

export const handleResetPasswordStart = async (
  email: string
): Promise<boolean> => {
  try {
    const res = await PublicAPI.post("account/forgot_password_start/", {
      email,
    });
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }

  return false;
};

export const handleResetPasswordConfirm = async (
  email: string,
  code: string,
  password: string
): Promise<boolean> => {
  try {
    const res = await PublicAPI.post("account/forgot_password_confirm/", {
      email,
      code,
      password,
    });
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }

  return false;
};

export const handleUpdatePassword = async (
  form: UpdatePasswordForm,
  setError: (error: string) => void
): Promise<boolean> => {
  try {
    const res = await ProtectedAPI.post("account/update_password/", {
      old_password: form.oldPassword,
      new_password: form.newPassword,
    });
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    setError("Unable to change password. Verify current password is correct.");
  }

  return false;
};
