import React from "react";
import { Divider, HStack, Image, Tag, Text, VStack } from "@chakra-ui/react";

import { RestaurantMenuItem } from "../types/state";
import { useGlobalContext } from "../../../context/globalContext";
import { useSearchContext } from "../context/searchContext";
import AllergensList from "../../../components/AllergensList";
import {
  ALLERGENS_CUSTOMER_CONFIG,
  DIETS_CONFIG,
} from "../../../data/constants";

interface Props {
  item: RestaurantMenuItem;
}

const MenuItemCard: React.FC<Props> = ({ item }) => {
  const { isMobile } = useGlobalContext();
  const { setCurrentMenuItem } = useSearchContext();

  return (
    <VStack
      minH="120px"
      flex={isMobile ? "0 0 100%" : "0 0 48%"}
      justify="space-between"
      p="1em"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      cursor="pointer"
      transition="all 0.2s ease-in-out"
      _hover={{
        borderColor: "gray.400",
      }}
      onClick={() => {
        setCurrentMenuItem(item);
      }}
    >
      <HStack align="flex-start" w="100%" spacing={3}>
        {item.photoHref && (
          <Image
            src={item.photoHref}
            boxSize="64px"
            borderRadius="full"
            objectFit="cover"
          />
        )}
        <VStack w="100%">
          <HStack w="100%" align="flex-start" justify="space-between">
            <Text flex={1} color="gray.600" fontWeight="bold">
              {item.name}
            </Text>
            {item.requiresSubstitution ? (
              <Tag size="sm" colorScheme="orange" whiteSpace="nowrap">
                Requires substitution
              </Tag>
            ) : (
              <Tag size="sm" colorScheme="green" whiteSpace="nowrap">
                All good
              </Tag>
            )}
          </HStack>

          <Text w="100%" color="gray.600" fontSize="0.9em">
            {item.description}
          </Text>
        </VStack>
      </HStack>

      <HStack w="100%" justify="space-between">
        <VStack align="flex-start">
          <VStack align="flex-start" spacing={1}>
            <Text color="gray.500" fontSize="0.8em">
              Contains
            </Text>
            {item.allergens.length === 0 ? (
              <Text fontSize="0.8em" color="gray.600" fontStyle="italic">
                None of your selected allergens listed
              </Text>
            ) : (
              <AllergensList
                allergens={item.allergens}
                allergensConfig={ALLERGENS_CUSTOMER_CONFIG}
              />
            )}
          </VStack>
          <VStack align="flex-start" spacing={1}>
            <Text color="gray.500" fontSize="0.8em">
              Safe for
            </Text>
            {item.diets.length === 0 ? (
              <Text fontSize="0.8em" color="gray.600" fontStyle="italic">
                Not suitable for vegans, vegetarians, or pescatarians
              </Text>
            ) : (
              <AllergensList
                allergens={item.diets}
                allergensConfig={DIETS_CONFIG}
              />
            )}
          </VStack>
        </VStack>

        <Text color="gray.600" fontSize="0.9em" fontWeight="bold">
          ${item.price}
        </Text>
      </HStack>

      {item.crossContactDescription && (
        <VStack align="flex-start" w="100%" mt="8px">
          <Divider />
          <Tag colorScheme="orange" size="sm" px="8px" py="6px">
            {item.crossContactDescription}
          </Tag>
        </VStack>
      )}
    </VStack>
  );
};

export default MenuItemCard;
