import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  breakpoints: {
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1280px",
    "2xl": "1536px",
  },
  colors: {
    accent: {},
    primary: {
      50: "#E6F7F8",
      75: "#D2F0F2",
      600: "#156F75",
    },
    secondary: {},
  },
  components: {},
  fonts: {
    heading: "Lato",
    body: "Lato",
  },
  styles: {
    global: () => ({
      "html, body": {
        h: "100%",
        bg: "white",
      },
      "#root": {
        h: "100%",
      },
      body: {},
      "#public-layout-scrollable": {
        "::-webkit-scrollbar": {
          width: "0px",
          background: "transparent" /* make scrollbar transparent */,
        },
      },
    }),
  },
});

export default theme;
