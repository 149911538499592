import React from "react";
import { Routes, Route } from "react-router-dom";

import { AuthProvider } from "./features/authentication/context/authContext";
import { GlobalProvider } from "./context/globalContext";
import { SearchProvider } from "./features/customer/context/searchContext";

import LayoutAdmin from "./layouts/LayoutAdmin";
import LayoutBusiness from "./layouts/LayoutBusiness";
import LayoutCustomer from "./layouts/LayoutCustomer";
import LayoutPublic from "./layouts/LayoutPublic";
import LayoutDynamic from "./layouts/LayoutDynamic";

import IndexPage from "./pages/Index";
import LoginPage from "./pages/Login";
import NotFoundPage from "./pages/NotFound";
import RegisterCustomerPage from "./pages/Customer/Register";
import RegisterBusinessPage from "./pages/RegisterBusiness";
import ResetPasswordPage from "./pages/ResetPassword";
import BusinessDashboardPage from "./pages/Business/Dashboard";
import BusinessLocationsPage from "./pages/Business/Locations";
import BusinessBranchEditPage from "./pages/Business/LocationEdit";
import BusinessLocationNewPage from "./pages/Business/LocationNew";
import BusinessMenusPage from "./pages/Business/Menus";
import BusinessMenuEditPage from "./pages/Business/MenuEdit";
import BusinessMenuNewPage from "./pages/Business/MenuNew";
import BusinessSettingsPage from "./pages/Business/Preferences";
import VerifyEmailPage from "./pages/VerifyEmail";

import CustomerSearchPage from "./pages/Customer/Search";
import RestaurantBubbleRedirect from "./pages/Customer/RestaurantBubbleRedirect";
import RestaurantProfilePage from "./pages/Customer/RestaurantProfile";
import MenuProfilePage from "./pages/Customer/MenuProfile";
import CustomerSettingsPage from "./pages/Customer/Settings";
import CompleteRegistrationPage from "./pages/CompleteRegistration";
import { BusinessProvider } from "./features/business/context/businessContext";

interface Props {}

const App: React.FC<Props> = () => {
  return (
    <GlobalProvider>
      <AuthProvider>
        <BusinessProvider>
          <Routes>
            {/* index page is for coordinating redirects only */}
            <Route path="/" Component={IndexPage} />

            <Route element={<LayoutDynamic />}>
              <Route path="verify-email" element={<VerifyEmailPage />} />
            </Route>

            <Route element={<LayoutAdmin />}>
              <Route path="/admin">
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>

            <Route element={<LayoutPublic />}>
              <Route path="login" element={<LoginPage />} />
              <Route path="register" element={<RegisterCustomerPage />} />
              <Route path="get-started" element={<RegisterBusinessPage />} />
              <Route path="reset-password" element={<ResetPasswordPage />} />
              <Route path="complete-registration/:uid" element={<CompleteRegistrationPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
            <Route element={<LayoutCustomer />}>
              <Route path="/settings" element={<CustomerSettingsPage />} />
              <Route element={<SearchProvider />}>
                <Route path="search" element={<CustomerSearchPage />} />
                <Route path="/menu/:stub" element={<RestaurantBubbleRedirect />} />
                <Route path="/restaurants/:uid" element={<RestaurantProfilePage />} />
                <Route
                  path="/restaurants/:branchUid/menus/:menuUid"
                  element={<MenuProfilePage />}
                />
                {/* <Route path="/menus/:uid" element={<MenuProfilePage />} /> */}
              </Route>
            </Route>
            <Route element={<LayoutBusiness />} path="business">
              <Route path="dashboard" element={<BusinessDashboardPage />} />
              <Route path="preferences" element={<BusinessSettingsPage />} />
              <Route path="locations">
                <Route path="" element={<BusinessLocationsPage />} />
                <Route path="new" element={<BusinessLocationNewPage />} />
                <Route path=":uid" element={<BusinessBranchEditPage />} />
              </Route>
              <Route path="menus">
                <Route path="" element={<BusinessMenusPage />} />
                <Route path="new" element={<BusinessMenuNewPage />} />
                <Route path=":uid" element={<BusinessMenuEditPage />} />
              </Route>
            </Route>
          </Routes>
        </BusinessProvider>
      </AuthProvider>
    </GlobalProvider>
  );
};

export default App;
