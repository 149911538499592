import React from "react";
import { Flex, Heading } from "@chakra-ui/react";

interface Props {}

const NotFoundPage: React.FC<Props> = () => {
  return (
    <Flex flex={1} align="center" justify="center" mb="40px">
      <Heading size="sm" color="gray.600">
        Oops, this page is not yet a page.
      </Heading>
    </Flex>
  );
};

export default NotFoundPage;
