import { ProtectedAPI, PublicAPI } from "../../../api";
import { Account, AccountBasics } from "../types/state";

export const handleGetAccountBasics = async (uid: string): Promise<AccountBasics | null> => {
  try {
    const res = await PublicAPI.get("account/get_account_basics", {
      params: {
        uid,
      },
    });
    if (res.status === 200) {
      const data = res.data;
      return {
        uidCognito: data.uid_cognito,
        userType: data.user_type,
        firstName: data.first_name,
        lastName: data.last_name,
        phoneNumber: data.phone_number,
        allergens: data.allergens,
        diets: data.diets,
        organizationName: data.organization_name,
      } as AccountBasics;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const handleGetAccount = async (): Promise<Account | null> => {
  try {
    const res = await ProtectedAPI.get("account/get_account");
    if (res.status === 200) {
      const data = res.data;
      const account: Account = {
        datetimeCreated: new Date(data.datetime_created),
        uidCognito: data.uid_cognito,
        userType: data.user_type,
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        phoneNumber: data.phone_number,
        allergens: data.allergens,
        diets: data.diets,
        isEmailVerified: data.is_email_verified,
        organization: {
          datetimeCreated: new Date(data.organization.datetime_created),
          uid: data.organization.uid,
          name: data.organization.name,
        },
      };
      return account;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};
