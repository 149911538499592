import React, { useEffect } from "react";
import {
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { HiOutlineDocumentText } from "react-icons/hi2";

import { useGlobalContext } from "../../context/globalContext";
import { useSearchContext } from "../../features/customer/context/searchContext";
import RestaurantSearchBar from "../../features/customer/components/RestaurantSearchBar";
import RestaurantCard, {
  RestaurantCardSkelton,
} from "../../features/customer/components/RestaurantCard";

interface Props {}

const CustomerSearchPage: React.FC<Props> = () => {
  const theme = useTheme();
  const { isMobile } = useGlobalContext();
  const { restaurants, searchLoading, setCurrentBranch } = useSearchContext();

  useEffect(() => {
    setCurrentBranch(null);
  }, [setCurrentBranch]);

  return (
    <VStack w="100%" pb={16}>
      <Flex
        align="center"
        h={isMobile ? "auto" : "120px"}
        w="100%"
        py={2}
        bgGradient="linear(to-br, primary.75, primary.50)"
      >
        <VStack
          align="flex-start"
          spacing={1}
          w="100%"
          m="0 auto"
          maxW={`${theme.breakpoints.md}`}
          px={3}
        >
          <Heading color="teal.700" fontSize={isMobile ? "1.2em" : "1.4em"}>
            Search restaurants
          </Heading>
          <Text
            color="gray.700"
            maxW="80%"
            fontSize={isMobile ? "0.8em" : "1em"}
          >
            Find restaurants tailored to your allergy and dietary preferences.
          </Text>
        </VStack>
      </Flex>

      <RestaurantSearchBar />

      <VStack
        align="flex-start"
        w="100%"
        flex={1}
        m="0 auto"
        mt="20px"
        maxW={`${theme.breakpoints.md}`}
        px={2}
      >
        {restaurants && restaurants.length > 0 && !searchLoading && (
          <Text ms="4px" color="gray.400" fontSize="0.7em">
            Showing 1-{restaurants.length} of {restaurants.length} results
          </Text>
        )}

        {(restaurants && restaurants.length) > 0 || searchLoading ? (
          <SimpleGrid w="100%" columns={[1, 1, 2]} spacing={4}>
            {searchLoading || !restaurants ? (
              <>
                <RestaurantCardSkelton />
                <RestaurantCardSkelton />
                <RestaurantCardSkelton />
                <RestaurantCardSkelton />
                <RestaurantCardSkelton />
                <RestaurantCardSkelton />
              </>
            ) : (
              <>
                {restaurants.map((branch) => (
                  <RestaurantCard branch={branch} />
                ))}
              </>
            )}
          </SimpleGrid>
        ) : (
          <VStack
            w="100%"
            h="100%"
            align="center"
            justify="center"
            color="gray.400"
            spacing={4}
            p={2}
          >
            <Icon as={HiOutlineDocumentText} fontSize="3.6em" />
            <Text fontSize="1.2em">No restaurants found</Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

export default CustomerSearchPage;
