import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
  Textarea,
  Button,
  HStack,
  InputGroup,
  InputLeftElement,
  Checkbox,
} from "@chakra-ui/react";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi2";

import { useGlobalContext } from "../../../context/globalContext";
import { useBusinessContext } from "../context/businessContext";
import ItemsSelector from "../../../components/ItemsSelector";
import ItemsMultiSelect from "../../../components/ItemsMultiSelect";
import { ExtendedMenuItem } from "../types/forms";
import { MenuItem, MenuItemSubstitution } from "../types/state";
import { handleUpdateMenuItem } from "../services/update";
import { handleCreateMenuItem } from "../services/create";
import { ALLERGENS_RESTAURANT_CONFIG } from "../../../data/constants";

interface Props {
  menuUid: string;
  item: MenuItem | null;
}

const MenuItemForm: React.FC<Props> = ({ menuUid, item }) => {
  const { setModalLoading } = useGlobalContext();
  const { setCurrentMenuItem, setShowMenuItemForm } = useBusinessContext();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [photo, setPhoto] = useState<string | ArrayBuffer | null>(null);

  // fixme: form edits rerender component and cause lags

  const form = useFormik({
    initialValues: {
      photoFile: null,
      name: item?.name || "",
      description: item?.description || "",
      section: item?.section || "",
      price: item?.price || 0,
      crossContactDescription: item?.crossContactDescription || "",
      allergens: item?.allergens || [],
      // diets: item?.diets || [],
      substitutions: item?.substitutions || [],
      isPublished: item ? item.isPublished : true,
    } as ExtendedMenuItem,
    validate: (values) => {},
    onSubmit: async (values) => {
      setDisabled(true);
      setLoading(true);
      setModalLoading(true);

      console.log("values", values);

      if (item) {
        const updateSuccess = await handleUpdateMenuItem(item.uid, values);
        console.log("updateSuccess", updateSuccess);
        if (updateSuccess) {
          setIsOpen(false);
          setCurrentMenuItem(null);
          setShowMenuItemForm(false);
          window.location.reload(); // fixme: this is a hack
        }
      } else {
        const createSuccess = await handleCreateMenuItem(menuUid, values);
        console.log("createSuccess", createSuccess);
        if (createSuccess) {
          setIsOpen(false);
          setCurrentMenuItem(null);
          setShowMenuItemForm(false);
          window.location.reload(); // fixme: this is a hack
        }
      }

      setModalLoading(false);
      setLoading(false);
      setDisabled(false);
    },
  });

  useEffect(() => {
    if (form.values.name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [form.values.name]);

  const handleAddSubstitution = useCallback(
    (substitution: MenuItemSubstitution) => {
      const newSubstitutions = [...form.values.substitutions, substitution];
      form.setFieldValue("substitutions", newSubstitutions);
    },
    [form]
  );

  const handleRemoveSubstitution = useCallback(
    (index: number) => {
      const newSubstitutions = [...form.values.substitutions];
      newSubstitutions.splice(index, 1);
      form.setFieldValue("substitutions", newSubstitutions);
    },
    [form]
  );

  let photoContent;
  if (photo && form.values.photoFile) {
    photoContent = (
      <Image
        src={photo as string}
        alt="menu item photo"
        objectFit="cover"
        w="100%"
      />
    );
  } else if (item && item.photoHref) {
    photoContent = (
      <Image src={item.photoHref} alt="menu photo" objectFit="cover" w="100%" />
    );
  } else {
    photoContent = (
      <>
        <Icon as={HiOutlinePlus} fontSize="1.1em" color="gray.500" />
        <Text color="gray.500" ms="4px">
          Add photo
        </Text>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        setIsOpen(false);
        setCurrentMenuItem(null);
        setShowMenuItemForm(false);
        form.resetForm();
      }}
      size="lg"
    >
      <ModalOverlay backdropFilter="blur(8px)" />
      <ModalContent mx={2} mt={2}>
        <ModalHeader color="gray.700">
          {item ? "Edit menu item" : "Add menu item"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="flex-start" spacing={3}>
            <Flex
              cursor="pointer"
              borderRadius="md"
              overflow="hidden"
              align="center"
              justify="center"
              bg="gray.100"
              w="100%"
              h={["200px", "200px", "240px"]}
              onClick={() => {
                const input = document.getElementById("itemImageInput");
                input?.click();
              }}
            >
              {photoContent}
              <input
                type="file"
                id="itemImageInput"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                      if (event.target?.result) {
                        setPhoto(event.target.result);
                        form.setFieldValue("photoFile", file);
                      }
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                style={{ display: "none" }}
              />
            </Flex>

            <HStack w="100%" justify="space-between">
              <Checkbox
                defaultChecked={form.values.isPublished}
                size="lg"
                color="gray.600"
                onChange={(e) => {
                  form.setFieldValue("isPublished", e.target.checked);
                }}
              >
                Is published
              </Checkbox>
            </HStack>

            <FormControl isRequired={true}>
              <FormLabel fontSize="0.8em" color="gray.500">
                Item name
              </FormLabel>
              <Input
                mt="-2px"
                name="name"
                type="text"
                placeholder="Name"
                value={form.values.name}
                onChange={form.handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="0.8em" color="gray.500">
                Description
              </FormLabel>
              <Textarea
                mt="-2px"
                name="description"
                placeholder="Description"
                value={form.values.description}
                onChange={form.handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="0.8em" color="gray.500">
                Section
              </FormLabel>
              <Input
                mt="-2px"
                name="section"
                type="text"
                placeholder="Section"
                value={form.values.section}
                onChange={form.handleChange}
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel fontSize="0.8em" color="gray.500">
                Price
              </FormLabel>
              <Input
                mt="-2px"
                name="price"
                type="number"
                placeholder="Price"
                value={form.values.price}
                onChange={form.handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="0.8em" color="gray.500">
                Allergens
              </FormLabel>
              <ItemsSelector
                field="allergens"
                items={ALLERGENS_RESTAURANT_CONFIG}
                selectedItems={form.values.allergens}
                setItemsField={form.setFieldValue}
              />
            </FormControl>
            {/* 
            <FormControl>
              <FormLabel fontSize="0.8em" color="gray.500">
                Dietary preferences
              </FormLabel>
              <ItemsSelector
                field="dietaryPreferences"
                items={ALLERGENS_RESTAURANT_CONFIG}
                selectedItems={form.values.diets}
                setItemsField={form.setFieldValue}
              />
            </FormControl>
            */}
            <FormControl>
              <FormLabel fontSize="0.8em" color="gray.500">
                Cross contact description
              </FormLabel>
              <Textarea
                mt="-2px"
                name="crossContactDescription"
                placeholder="Cross contact description"
                value={form.values.crossContactDescription}
                onChange={form.handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize="0.8em" color="gray.500">
                Create new substitution
              </FormLabel>
              <SubstitutionForm handleSubmit={handleAddSubstitution} />
            </FormControl>
            {form.values.substitutions.length > 0 && (
              <FormControl w="100%">
                <FormLabel fontSize="0.8em" color="gray.500">
                  Substitutions
                </FormLabel>
                <VStack align="flex-start">
                  {form.values.substitutions.map((substitution, index) => (
                    <SubstitutionItem
                      key={substitution.uid}
                      index={index}
                      substitution={substitution}
                      onRemove={handleRemoveSubstitution}
                    />
                  ))}
                </VStack>
              </FormControl>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter mt={["10px", "10px", "20px"]}>
          <HStack w="100%" justify="space-between">
            <Button
              isDisabled={loading}
              onClick={() => {
                setIsOpen(false);
                setCurrentMenuItem(null);
                setShowMenuItemForm(false);
                form.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isDisabled={disabled || loading}
              isLoading={loading}
              onClick={() => {
                console.log(form.values);
                form.handleSubmit();
              }}
            >
              Save changes
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface FormProps {
  handleSubmit: (substitution: MenuItemSubstitution) => void;
}

const SubstitutionForm: React.FC<FormProps> = ({ handleSubmit }) => {
  const [disabled, setDisabled] = useState(true);

  const form = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: 0.0,
      allergensAdded: [],
      allergensReplaced: [],
    } as MenuItemSubstitution,
    validate: (values) => {
      if (!values.name || !values.allergensReplaced) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    },
    onSubmit: (values) => {
      handleSubmit(values);
      form.resetForm();
    },
  });

  return (
    <VStack>
      <HStack w="100%" justify="space-between">
        <Input
          name="name"
          type="text"
          placeholder="Name"
          value={form.values.name}
          onChange={form.handleChange}
        />

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children="$"
          />
          <Input
            name="price"
            type="number"
            placeholder="Price"
            value={form.values.price}
            onChange={form.handleChange}
          />
        </InputGroup>
      </HStack>
      <Input
        name="description"
        type="text"
        placeholder="Description"
        value={form.values.description}
        onChange={form.handleChange}
      />
      <HStack w="100%" mt="4px">
        <ItemsMultiSelect
          field="allergens"
          items={ALLERGENS_RESTAURANT_CONFIG}
          selectName="Allergens added"
          selectedItems={form.values.allergensAdded}
          setSelectedItems={(value) =>
            form.setFieldValue("allergensAdded", value)
          }
          isDisabled={false}
          width="100%"
        />
        <ItemsMultiSelect
          field="allergens"
          items={ALLERGENS_RESTAURANT_CONFIG}
          selectName="Allergens replaced"
          selectedItems={form.values.allergensReplaced}
          setSelectedItems={(value) =>
            form.setFieldValue("allergensReplaced", value)
          }
          isDisabled={false}
          width="100%"
        />
      </HStack>
      <HStack w="100%" justify="space-between">
        <Button
          size="sm"
          variant="outline"
          colorScheme="blackAlpha"
          onClick={() => {
            form.resetForm();
          }}
        >
          Clear
        </Button>
        <Button
          size="sm"
          variant="outline"
          colorScheme="teal"
          isDisabled={disabled}
          onClick={() => {
            form.handleSubmit();
          }}
        >
          Add
        </Button>
      </HStack>
    </VStack>
  );
};

interface ItemProps {
  index: number;
  substitution: MenuItemSubstitution;
  onRemove: (index: number) => void;
}

const SubstitutionItem: React.FC<ItemProps> = ({
  index,
  substitution,
  onRemove,
}) => {
  return (
    <VStack
      w="100%"
      spacing={1}
      align="flex-start"
      bg="gray.50"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      px="10px"
      py="6px"
    >
      <HStack w="100%" justify="space-between">
        <Text color="gray.600" fontWeight="bold">
          {substitution.name}
        </Text>
        <Icon
          as={HiOutlineTrash}
          color="gray.600"
          cursor="pointer"
          onClick={() => {
            onRemove(index);
          }}
        />
      </HStack>
      <Text fontSize="0.8em" color="gray.600">
        {substitution.description}
      </Text>
      <HStack w="100%" mt="4px">
        <ItemsMultiSelect
          field="allergens"
          items={ALLERGENS_RESTAURANT_CONFIG}
          selectName="Allergens added"
          selectedItems={substitution.allergensAdded}
          setSelectedItems={() => {}}
          isDisabled={false}
          width="100%"
        />
        <ItemsMultiSelect
          field="allergens"
          items={ALLERGENS_RESTAURANT_CONFIG}
          selectName="Allergens replaced"
          selectedItems={substitution.allergensReplaced}
          setSelectedItems={() => {}}
          isDisabled={false}
          width="100%"
        />
      </HStack>
    </VStack>
  );
};

export default MenuItemForm;
