import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormLabel,
  Input,
  HStack,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import { handleUpdatePassword } from "../../../features/authentication/services/password";
import { UpdatePasswordForm } from "../../../features/authentication/types/forms";
import { useGlobalContext } from "../../../context/globalContext";

interface Props {}

const BusinessSecurityPanel: React.FC<Props> = () => {
  const { isMobile, setModalLoading } = useGlobalContext();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const form = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    } as UpdatePasswordForm,
    validate: (values) => {
      if (
        !values.oldPassword ||
        !values.newPassword ||
        !values.confirmPassword ||
        values.newPassword !== values.confirmPassword ||
        values.newPassword.length < 7
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    },
    onSubmit: async (values, { resetForm }) => {
      setError(null);
      setLoading(true);
      setModalLoading(true);

      const updateSuccess = await handleUpdatePassword(values, setError);
      if (updateSuccess) {
        setShowSuccess(true);
        setDisabled(true);
        resetForm();
      }

      setLoading(false);
      setModalLoading(false);
    },
  });

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 10000);
    }
  }, [showSuccess]);

  return (
    <VStack align="flex-start" w="100%" h="100%">
      <Text color="gray.400" fontSize="0.8em">
        Passwords must be at least 7 characters long.
      </Text>
      <FormControl>
        <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
          Current password
        </FormLabel>
        <Input
          name="oldPassword"
          type="password"
          color="gray.600"
          placeholder="Current password"
          size={isMobile ? "lg" : "md"}
          value={form.values.oldPassword}
          onChange={form.handleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
          New password
        </FormLabel>
        <Input
          name="newPassword"
          type="password"
          color="gray.600"
          placeholder="New password"
          size={isMobile ? "lg" : "md"}
          value={form.values.newPassword}
          onChange={form.handleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel mb={1} ms={1} color="gray.400" fontSize="0.9em">
          Confirm password
        </FormLabel>
        <Input
          name="confirmPassword"
          type="password"
          color="gray.600"
          placeholder="Confirm new password"
          size={isMobile ? "lg" : "md"}
          value={form.values.confirmPassword}
          onChange={form.handleChange}
        />
      </FormControl>

      <HStack w="100%" align="end" justify="space-between">
        <Fade in={showSuccess ? true : false}>
          <Box color="green.500" fontSize="1em" fontWeight="bold">
            Password updated.
          </Box>
        </Fade>
        <VStack mt="10px" align="flex-end">
          <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
            <Fade in={error ? true : false}>
              <Box
                w="100%"
                color="red.600"
                fontSize="0.8em"
                fontWeight="bold"
                textAlign="end"
              >
                {error}
              </Box>
            </Fade>
          </Flex>
          <Button
            size={isMobile ? "lg" : "md"}
            colorScheme="teal"
            isDisabled={disabled || loading}
            isLoading={loading}
            onClick={() => {
              form.handleSubmit();
            }}
          >
            Update password
          </Button>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default BusinessSecurityPanel;
