import React from "react";
import {
  Badge,
  Flex,
  HStack,
  Image,
  Link as ChakraLink,
  Text,
  VStack,
  Skeleton,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { MenuOverview } from "../types/state";
import { useGlobalContext } from "../../../context/globalContext";

interface Props {
  menu: MenuOverview;
  link: string;
}

const BusinessMenuCard: React.FC<Props> = ({ menu, link }) => {
  const { isMobile } = useGlobalContext();

  return (
    <ChakraLink as={RouterLink} to={link} w="100%">
      <VStack align="flex-start" w="100%" spacing={1}>
        <Flex
          borderRadius="md"
          overflow="hidden"
          bg="gray.100"
          w="100%"
          h={isMobile ? "200px" : "240px"}
        >
          <Image
            src={menu.photoHref}
            fallbackSrc={menu.photoHref}
            alt="menu photo"
            objectFit="cover"
            w="100%"
          />
        </Flex>
        <HStack w="100%" justify="space-between">
          <Text fontSize="1.1em" color="gray.600">
            {menu.name}
          </Text>
          <Badge colorScheme={menu.isPublished ? "green" : "orange"} size="sm">
            {menu.isPublished ? "published" : "not published"}
          </Badge>
        </HStack>
      </VStack>
    </ChakraLink>
  );
};

export const BusinessMenuCardSkeleton: React.FC = () => {
  const { isMobile } = useGlobalContext();

  return (
    <VStack>
      <Skeleton borderRadius="md" w="100%" h={isMobile ? "200px" : "240px"} />
      <Skeleton w="100%" h="20px" />
    </VStack>
  );
};

export default BusinessMenuCard;
