import React from "react";
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { HiPlus, HiOutlineDocumentText } from "react-icons/hi2";

import { useBusinessContext } from "../../features/business/context/businessContext";
import { useGlobalContext } from "../../context/globalContext";
import BusinessMenuCard, {
  BusinessMenuCardSkeleton,
} from "../../features/business/components/MenuCard";

interface Props {}

const BusinessMenusPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { isMobile } = useGlobalContext();
  const { loadingMenus, menus } = useBusinessContext();

  let content;
  if (loadingMenus) {
    content = (
      <Grid
        mt={isMobile ? "10px" : "20px"}
        w="100%"
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
        gap={4}
      >
        <GridItem w="100%">
          <BusinessMenuCardSkeleton />
        </GridItem>
        <GridItem w="100%">
          <BusinessMenuCardSkeleton />
        </GridItem>
        <GridItem w="100%">
          <BusinessMenuCardSkeleton />
        </GridItem>
        <GridItem w="100%">
          <BusinessMenuCardSkeleton />
        </GridItem>
      </Grid>
    );
  } else if (menus.length > 0 && !loadingMenus) {
    content = (
      <Grid
        mt={isMobile ? "10px" : "20px"}
        w="100%"
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
        gap={4}
      >
        {menus.map((menu) => (
          <GridItem w="100%">
            <BusinessMenuCard
              key={menu.uid}
              menu={menu}
              link={`/business/menus/${menu.uid}`}
            />
          </GridItem>
        ))}
      </Grid>
    );
  } else {
    content = (
      <VStack
        w="100%"
        h="100%"
        align="center"
        justify="center"
        color="gray.400"
        spacing={4}
        p={2}
      >
        <Icon as={HiOutlineDocumentText} fontSize="3.6em" />
        <Text fontSize="1.2em">You don't have any menus yet.</Text>
      </VStack>
    );
  }

  return (
    <VStack align="flex-start" w="100%" maxW="860px">
      <HStack w="100%" align="flex-start" justify="space-between">
        <Text color="teal.600" fontSize="1.2em" fontWeight="bold" mb="20px">
          Menus
        </Text>
        <Button
          colorScheme="teal"
          rightIcon={<HiPlus />}
          size="sm"
          onClick={() => navigate("/business/menus/new")}
        >
          Create new menu
        </Button>
      </HStack>
      {content}
    </VStack>
  );
};

export default BusinessMenusPage;
