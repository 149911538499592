export function isEmailValid(email: string): boolean {
  const re: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formatPhoneNumber(phoneNumberString: string) {
  if (!phoneNumberString) return "";

  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");

  if (cleaned.length === 11 && cleaned[0] === "1") {
    cleaned = cleaned.substring(1);
  }

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return phoneNumberString;
}
