import React, { useState } from "react";
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Tag,
  Text,
  Divider,
  HStack,
} from "@chakra-ui/react";

import {
  ALLERGENS_RESTAURANT_CONFIG,
  ALLERGENS_CUSTOMER_CONFIG,
  DIETS_CONFIG,
} from "../../../data/constants";
import { RestaurantMenuItem } from "../types/state";
import AllergensList from "../../../components/AllergensList";

interface Props {
  item: RestaurantMenuItem;
  setItem: React.Dispatch<React.SetStateAction<RestaurantMenuItem | null>>;
}

const MenuItemOverlay: React.FC<Props> = ({ item, setItem }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        setItem(null);
      }}
    >
      <ModalOverlay backdropFilter="blur(8px)" />
      <ModalContent mx={2}>
        <ModalHeader color="gray.700">{item.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="flex-start" spacing={3}>
            {item.photoHref && (
              <Image
                w="100%"
                maxH="240px"
                objectFit="cover"
                src={item.photoHref}
                alt={item.name}
                borderRadius="md"
              />
            )}
            <Text color="gray.600">{item.description}</Text>
            <VStack align="flex-start" spacing={1}>
              <Text color="gray.500" fontSize="0.8em">
                Contains
              </Text>
              {item.allergens.length > 0 ? (
                <AllergensList
                  allergens={item.allergens}
                  showLabels={true}
                  allergensConfig={ALLERGENS_CUSTOMER_CONFIG}
                />
              ) : (
                <Text fontSize="0.8em" color="gray.600" fontStyle="italic">
                  None of your selected allergens listed
                </Text>
              )}
            </VStack>

            <VStack align="flex-start" spacing={1}>
              <Text color="gray.500" fontSize="0.8em">
                Safe for
              </Text>
              {item.diets.length > 0 ? (
                <AllergensList
                  allergens={item.diets}
                  showLabels={true}
                  allergensConfig={DIETS_CONFIG}
                />
              ) : (
                <Text fontSize="0.8em" color="gray.600" fontStyle="italic">
                  Not suitable for vegans, vegetarians, or pescatarians
                </Text>
              )}
            </VStack>

            {item.crossContactDescription && (
              <Tag colorScheme="orange" size="sm" px="8px" py="6px">
                {item.crossContactDescription}
              </Tag>
            )}

            {item.substitutions?.length > 0 && (
              <VStack w="100%" align="flex-start" mt="10px">
                <HStack w="100%" justify="space-between">
                  <Text color="teal.700" fontSize="1em" fontWeight="bold">
                    Substitutions
                  </Text>
                  {item.requiresSubstitution ? (
                    <Tag size="sm" colorScheme="yellow">
                      Substitution required
                    </Tag>
                  ) : (
                    <Tag size="sm" colorScheme="green">
                      Subtitution not required
                    </Tag>
                  )}
                </HStack>
                <Divider borderTop="1px solid" borderColor="gray.200" />
                <VStack w="100%">
                  {item.substitutions.map((sub) => (
                    <VStack align="flex-start" w="100%">
                      <HStack w="100%" justify="space-between">
                        <Text color="gray.600" fontWeight="bold">
                          {sub.name}
                        </Text>
                        <Text color="gray.600">${sub.price}</Text>
                      </HStack>
                      <Text w="100%" color="gray.600" fontSize="0.9em">
                        {sub.description}
                      </Text>
                      <HStack
                        w="100%"
                        align="flex-start"
                        justify="space-between"
                      >
                        <VStack align="flex-start" w="100%">
                          <Text color="gray.500" fontSize="0.7em">
                            Allergens added
                          </Text>
                          {sub.allergensAdded.length === 0 ? (
                            <Text color="gray.500" fontSize="0.8em">
                              None
                            </Text>
                          ) : (
                            <AllergensList
                              allergens={sub.allergensAdded}
                              allergensConfig={ALLERGENS_RESTAURANT_CONFIG}
                            />
                          )}
                        </VStack>
                        <VStack align="flex-start" w="100%">
                          <Text color="gray.500" fontSize="0.7em">
                            Allergens replaced
                          </Text>
                          {sub.allergensReplaced.length === 0 ? (
                            <Text color="gray.500" fontSize="0.8em">
                              None
                            </Text>
                          ) : (
                            <AllergensList
                              allergens={sub.allergensReplaced}
                              allergensConfig={ALLERGENS_RESTAURANT_CONFIG}
                            />
                          )}
                        </VStack>
                      </HStack>
                      <Divider />
                    </VStack>
                  ))}
                </VStack>
              </VStack>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <VStack>
            <Text color="gray.600" fontWeight="bold">
              ${item.price}
            </Text>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MenuItemOverlay;
