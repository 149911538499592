import { Token } from "../types/state";
import { TOKEN_KEY } from "../../../data/constants";

export const isTokenValid = (token: Token | null): boolean => {
  const tokenStr = localStorage.getItem(TOKEN_KEY);
  if (!token || !token.datetimeRefreshCreated || !tokenStr) return false;

  return (
    token.app === "allergood" && // differentiate from other apps on localhost
    token.datetimeRefreshCreated.getTime() + token.refreshExpiresIn >=
      token.refreshExpiresIn
  );
};
