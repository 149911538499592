import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { isEmailValid } from "../../../utils/text";

interface RegisterContactFormProps {
  form: any;
  error: string | null;
  loading: boolean;
  isMobile: boolean;
  onContinue: () => void;
}
const RegisterContactForm: React.FC<RegisterContactFormProps> = ({
  form,
  error,
  loading,
  isMobile,
  onContinue,
}) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(
      !form.values.businessName ||
        !isEmailValid(form.values.email) ||
        form.values.password.length < 7
    );
  }, [form.values, setDisabled]);

  return (
    <form>
      <VStack align="flex-start" spacing={2}>
        <Input
          name="businessName"
          type="text"
          placeholder="Business name *"
          size={isMobile ? "lg" : "md"}
          value={form.values.businessName}
          onChange={form.handleChange}
          isRequired
        />
        <HStack>
          <Input
            name="firstName"
            type="text"
            placeholder="First name"
            size={isMobile ? "lg" : "md"}
            value={form.values.firstName}
            onChange={form.handleChange}
          />
          <Input
            name="lastName"
            type="text"
            placeholder="Last name"
            size={isMobile ? "lg" : "md"}
            value={form.values.lastName}
            onChange={form.handleChange}
          />
        </HStack>

        <Input
          name="phoneNumber"
          type="phone"
          placeholder="Phone number"
          size={isMobile ? "lg" : "md"}
          value={form.values.phoneNumber}
          onChange={form.handleChange}
        />

        <Input
          name="email"
          type="email"
          size={isMobile ? "lg" : "md"}
          placeholder="Email *"
          value={form.values.email}
          onChange={form.handleChange}
          isRequired
        />

        <Input
          name="password"
          type="password"
          autoComplete="off"
          placeholder="Password *"
          value={form.values.password}
          onChange={form.handleChange}
          isRequired
        />

        <Button
          colorScheme="teal"
          size="md"
          w="100%"
          isDisabled={disabled || loading}
          isLoading={loading}
          onClick={() => {
            onContinue();
          }}
        >
          Sign up
        </Button>

        <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
          <Fade in={error ? true : false}>
            <Box w="100%" color="red.700" fontSize="0.8em" textAlign="end">
              {error}
            </Box>
          </Fade>
        </Flex>

        <VStack
          align="flex-start"
          fontSize={isMobile ? "0.9em" : "0.8em"}
          mt="-4px"
        >
          <HStack>
            <Text color="gray.600">Looking to sign up as a customer?</Text>
            <ChakraLink
              color="teal.600"
              fontWeight="bold"
              as={RouterLink}
              to="/register"
              replace
            >
              Sign up
            </ChakraLink>
          </HStack>
          <HStack>
            <Text color="gray.600">Already have an account?</Text>
            <ChakraLink
              color="teal.600"
              fontWeight="bold"
              as={RouterLink}
              to="/login"
              replace
            >
              Log in
            </ChakraLink>
          </HStack>
        </VStack>
      </VStack>
    </form>
  );
};

export default RegisterContactForm;
