import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { HiPlus, HiOutlineTrash, HiOutlinePencilSquare, HiOutlineBookOpen } from "react-icons/hi2";

import { ALLERGENS_RESTAURANT_CONFIG } from "../../../data/constants";
import { useGlobalContext } from "../../../context/globalContext";
import { useBusinessContext } from "../context/businessContext";
import { MenuDetails, MenuItem, MenuItemUpdate } from "../types/state";
import AllergensList from "../../../components/AllergensList";

interface Props {
  menu: MenuDetails;
}

const MenuItemList: React.FC<Props> = ({ menu }) => {
  const { isMobileOrTablet } = useGlobalContext();
  const { setCurrentMenuItem, setShowMenuItemForm } = useBusinessContext();
  const [selectedSection, setSelectedSection] = React.useState<string>("all");
  const [filteredMenuItems, setFilteredMenuItems] = React.useState<MenuItem[] | undefined>(
    menu.menuItems
  );

  const sections = new Set<string>();
  menu.menuItems?.forEach((item) => {
    if (item.section) {
      sections.add(item.section.replace(/ /g, ""));
    }
  });
  const orderedSections = Array.from(sections).sort();

  useEffect(() => {
    if (selectedSection === "all") {
      setFilteredMenuItems(menu.menuItems);
    } else {
      setFilteredMenuItems(
        menu.menuItems?.filter(
          (item) => item.section && item.section.replace(/ /g, "") === selectedSection
        )
      );
    }
  }, [selectedSection, menu.menuItems]);

  return (
    <VStack w="100%" mt={isMobileOrTablet ? "10px" : "20px"} pb="40px">
      <HStack w="100%" justify="space-between">
        <Text color="teal.600" fontWeight="bold">
          Menu items ({filteredMenuItems?.length})
        </Text>
        <Stack direction={["column-reverse", "row", "row"]}>
          {menu.menuItems?.length && menu.menuItems?.length > 0 && (
            <Select
              w="200px"
              value={selectedSection}
              onChange={(e) => {
                setSelectedSection(e.target.value);
              }}
            >
              <option value="all">All sections</option>
              {Array.from(orderedSections).map((section) => (
                <option key={section} value={section}>
                  {section}
                </option>
              ))}
            </Select>
          )}
          <Button
            colorScheme="gray"
            rightIcon={<HiPlus />}
            size="sm"
            onClick={() => {
              setCurrentMenuItem(null);
              setShowMenuItemForm(true);
            }}
          >
            Add new item
          </Button>
        </Stack>
      </HStack>
      <VStack w="100%">
        {filteredMenuItems && filteredMenuItems.length > 0 ? (
          filteredMenuItems.map((item) => <MenuItemRow item={item} />)
        ) : (
          <VStack
            w="100%"
            h="200px"
            align="center"
            justify="center"
            color="gray.400"
            spacing={4}
            p={2}
          >
            <Icon as={HiOutlineBookOpen} fontSize="2.8em" />
            <Text fontSize="1em">No menu items yet</Text>
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};

interface ItemProps {
  item: MenuItem;
}

const MenuItemRow: React.FC<ItemProps> = ({ item }) => {
  const { setCurrentMenuItem, setShowMenuItemForm, menuItemUpdates, setMenuItemUpdates } =
    useBusinessContext();
  const [order, setOrder] = React.useState<number>(item.order);

  useEffect(() => {
    setOrder(item.order);
  }, [item.order]);

  return (
    <Stack
      direction={["column", "column", "row"]}
      justify="space-between"
      spacing={8}
      w="100%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      px="10px"
      py="10px"
      cursor="pointer"
      onClick={() => {
        setCurrentMenuItem(item);
        setShowMenuItemForm(true);
      }}
    >
      <VStack flex={1} align="flex-start" maxW="500px">
        <Text color="gray.700" fontWeight="bold">
          {item.name}
        </Text>
        <Text color="gray.600">{item.description}</Text>
        <Text color="gray.600" fontWeight="bold">
          ${item.price}
        </Text>
      </VStack>
      <Stack flex={1} justify="space-between" direction={["column", "row", "row"]}>
        <VStack align="flex-start">
          {item.allergens.length > 0 && (
            <VStack align="flex-start">
              <Text fontSize="0.8em" color="gray.600">
                Allergens
              </Text>
              <AllergensList
                allergens={item.allergens}
                showLabels={false}
                allergensConfig={ALLERGENS_RESTAURANT_CONFIG}
              />
            </VStack>
          )}
          {/* item.diets.length > 0 && (
            <VStack align="flex-start">
              <Text fontSize="0.8em" color="gray.600">
                Dietary preferences
              </Text>
              <DietsList diets={item.diets} showLabels={false} />
            </VStack>
          ) */}
        </VStack>
        <VStack align="flex-end" mt={["10px", "0px", "0px"]} justify="space-between">
          <VStack align="flex-end">
            <Text fontWeight="bold" color="gray.600" textAlign="right">
              {item.section}
            </Text>
            <Checkbox
              colorScheme="teal"
              isDisabled
              defaultChecked={item.isPublished}
              color="gray.600"
            >
              Published
            </Checkbox>
            <VStack align="flex-end" spacing={1}>
              <Text color="gray.500" fontSize="0.8em">
                Section order
              </Text>
              <NumberInput
                min={0}
                size="sm"
                value={order}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(value) => {
                  const newOrder = parseInt(value);
                  setOrder(newOrder);
                  let newUpdates;
                  if (menuItemUpdates) {
                    const index = menuItemUpdates.findIndex((update) => update.uid === item.uid);
                    // item already in array, so update order
                    if (index !== -1) {
                      const newUpdate = menuItemUpdates[index];
                      newUpdate.order = newOrder;
                      menuItemUpdates[index] = newUpdate;
                      newUpdates = menuItemUpdates;
                    } else {
                      // item not in array, so add it
                      newUpdates = [
                        ...menuItemUpdates,
                        {
                          uid: item.uid,
                          order: newOrder,
                        } as MenuItemUpdate,
                      ];
                    }
                  } else {
                    // array is empty, so add item
                    newUpdates = [
                      {
                        uid: item.uid,
                        order: parseInt(value),
                      } as MenuItemUpdate,
                    ];
                  }

                  setMenuItemUpdates(newUpdates);
                }}
              >
                <NumberInputField w="100px" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </VStack>
          </VStack>

          <HStack>
            <IconButton
              colorScheme="gray"
              aria-label="Edit item"
              size="sm"
              color="gray.500"
              icon={<HiOutlinePencilSquare />}
            />
            <IconButton
              colorScheme="gray"
              aria-label="Delete item"
              size="sm"
              color="gray.500"
              icon={<HiOutlineTrash />}
            />
          </HStack>
        </VStack>
      </Stack>
    </Stack>
  );
};

export default MenuItemList;
