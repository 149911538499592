import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { VStack, Text, Icon, Link as ChakraLink, Spinner } from "@chakra-ui/react";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";
import { Link as RouterLink } from "react-router-dom";

import { PublicAPI } from "../../api";

interface Props {}

const RestaurantBubbleRedirect: React.FC<Props> = () => {
  const { stub } = useParams<{ stub: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [uidNotFound, setUidNotFound] = useState(false);

  useEffect(() => {
    const fetchUid = async () => {
      try {
        console.log("stub", stub);
        const res = await PublicAPI.get(`business/get_branch_uid_from_stub/${stub}`);
        navigate(`/restaurants/${res.data.uid}`);
      } catch (error) {
        setUidNotFound(true);
        setIsLoading(false);
      }
    };

    fetchUid();
  }, [stub, navigate]);

  if (!isLoading && uidNotFound) {
    return (
      <VStack w="100%" align="center" justify="center" color="gray.400" spacing={4} p={2}>
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Resturant not found</Text>
        <ChakraLink as={RouterLink} to="/search" replace={true}>
          Go back to search
        </ChakraLink>
      </VStack>
    );
  }

  return (
    <VStack w="100%" align="center" justify="center" color="gray.400" spacing={4} p={2}>
      <Spinner size="xl" color="teal.600" />
    </VStack>
  );
};

export default RestaurantBubbleRedirect;
