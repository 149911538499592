import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Heading,
  Icon,
  Input,
  Link as ChakraLink,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

import { AccountBasics } from "../features/authentication/types/state";
import { CompleteCustomerForm } from "../features/registration/types/forms";
import { useGlobalContext } from "../context/globalContext";
import { useAuthContext } from "../features/authentication/context/authContext";
import { ALLERGENS_CUSTOMER_CONFIG, DIETS_CONFIG } from "../data/constants";
import {
  handleGetAccountBasics,
  handleGetAccount,
} from "../features/authentication/services/account";
import {
  handleCompleteBusinessRegistration,
  handleCompleteCustomerRegistration,
} from "../features/registration/services/register";
import ItemsSelector from "../components/ItemsSelector";

interface Props {}

const CompleteRegistrationPage: React.FC<Props> = () => {
  const { isMobile, setModalLoading } = useGlobalContext();
  const { setAccount, setToken, setRegisterBizActive, setRegisterCustActive } = useAuthContext();
  const [initialized, setInitialized] = useState(false);
  const [disabled, setDisabled] = useState(true); // submit disabled
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [accountBasics, setAccountBasics] = useState<AccountBasics | null>(null);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const { uid } = useParams();

  const form = useFormik({
    initialValues: {
      uid: uid,
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      allergens: [],
      diets: [],
      organizationName: "",
    } as CompleteCustomerForm,
    validate: (values) => {},
    onSubmit: async (values) => {
      setError(null);
      setLoadingUpdate(true);
      setModalLoading(true);

      if (accountBasics?.userType === "business") {
        setRegisterBizActive(true);
        const token = await handleCompleteBusinessRegistration(values, setError);
        if (token) {
          setToken(token);
          const newAccount = await handleGetAccount();
          if (newAccount) {
            setAccount(newAccount);
            setLoadingUpdate(false);
            setModalLoading(false);
            setRegisterBizActive(false);
            return;
          }
        }
      } else if (accountBasics?.userType === "customer") {
        setRegisterCustActive(true);
        const token = await handleCompleteCustomerRegistration(values, setError);
        if (token) {
          setToken(token);
          const newAccount = await handleGetAccount();
          if (newAccount) {
            setAccount(newAccount);
            setLoadingUpdate(false);
            setModalLoading(false);
            setRegisterCustActive(false);
            return;
          }
        }
      } else {
        setError("Account type not recognized");
        setLoadingUpdate(false);
        setModalLoading(false);
        return;
      }

      setError("Something went wrong. Please try again.");
      setLoadingUpdate(false);
      setModalLoading(false);
    },
  });

  useEffect(() => {
    const getAccount = async () => {
      if (!uid) return;
      setInitialized(true);
      setLoadingAccount(true);
      const newAccountBasics = await handleGetAccountBasics(uid);
      setLoadingAccount(false);
      if (newAccountBasics) {
        setAccountBasics(newAccountBasics);
        form.setValues({
          ...form.values,
          ...newAccountBasics,
          organizationName: newAccountBasics.organizationName || "",
          uid: uid,
        });
      } else {
        setAccountNotFound(true);
      }
    };

    if (!initialized) getAccount();
  }, [uid, form, initialized]);

  useEffect(() => {
    setError(null);
    const { password, confirmPassword } = form.values;
    setDisabled(password !== confirmPassword || password.length < 7);
  }, [form.values, setError, setDisabled]);

  if (accountNotFound || (!loadingAccount && !accountBasics)) {
    return (
      <VStack flex={1} w="100%" align="center" justify="center" color="gray.400" spacing={4}>
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Account not found or already complete</Text>
      </VStack>
    );
  }

  return (
    <VStack
      flex={1}
      pb="2em"
      w="100%"
      maxW="420px"
      align="center"
      justify={isMobile ? "flex-start" : "center"}
      mb={isMobile ? "40px" : "80px"}
    >
      <VStack align="flex-start" justify="center" spacing={4} w="100%">
        <Heading color="teal.600" size="md">
          Complete registration
        </Heading>
        <Text fontSize="0.8em" color="gray.500">
          Thanks for taking the time to sign up again for Allergood. We've been hard at work
          rebuilding our site to make it easier to use and more reliable. Since we don't have access
          to your password, we need you to set a new one.
        </Text>
        {loadingAccount ? (
          <Progress
            w="100%"
            size="xs"
            borderRadius="sm"
            colorScheme="teal"
            isIndeterminate={true}
          />
        ) : (
          <form style={{ width: "100%", maxWidth: "360px" }}>
            <VStack align="flex-start" spacing={2}>
              <HStack w="100%">
                <Input
                  name="firstName"
                  type="text"
                  placeholder="First name"
                  size={isMobile ? "lg" : "md"}
                  value={form.values.firstName}
                  onChange={form.handleChange}
                />
                <Input
                  name="lastName"
                  type="text"
                  placeholder="Last name"
                  size={isMobile ? "lg" : "md"}
                  value={form.values.lastName}
                  onChange={form.handleChange}
                />
              </HStack>

              <Input
                name="password"
                type="password"
                placeholder="Password"
                size={isMobile ? "lg" : "md"}
                value={form.values.password}
                onChange={form.handleChange}
              />

              <Input
                name="confirmPassword"
                type="password"
                placeholder="Confirm password"
                size={isMobile ? "lg" : "md"}
                value={form.values.confirmPassword}
                onChange={form.handleChange}
              />

              {accountBasics?.userType === "business" && (
                <Input
                  name="organizationName"
                  type="text"
                  placeholder="Business name"
                  size={isMobile ? "lg" : "md"}
                  value={form.values.organizationName}
                  onChange={form.handleChange}
                />
              )}

              {accountBasics?.userType === "customer" && (
                <>
                  <VStack align="flex-start" w="100%" spacing={2} mt="10px">
                    <Text fontSize="0.8em" color="gray.500">
                      What allergens are you sensitive to? Select all that apply.
                    </Text>
                    <ItemsSelector
                      field="allergens"
                      items={ALLERGENS_CUSTOMER_CONFIG}
                      selectedItems={form.values.allergens}
                      setItemsField={form.setFieldValue}
                    />
                  </VStack>

                  <VStack align="flex-start" w="100%" spacing={2} mt="10px">
                    <Text fontSize="0.8em" color="gray.500">
                      What are your eating preferences? Select all that apply.
                    </Text>
                    <ItemsSelector
                      field="diets"
                      items={DIETS_CONFIG}
                      selectedItems={form.values.diets}
                      setItemsField={form.setFieldValue}
                    />
                  </VStack>
                </>
              )}

              <Button
                colorScheme="teal"
                w="100%"
                mt="10px"
                isDisabled={disabled || loadingUpdate}
                isLoading={loadingUpdate}
                onClick={() => {
                  form.handleSubmit();
                }}
              >
                Complete sign up
              </Button>

              <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
                <Fade in={error ? true : false}>
                  <Box w="100%" color="red.700" fontSize="0.8em" textAlign="end">
                    {error}
                  </Box>
                </Fade>
              </Flex>

              <VStack align="flex-start" fontSize={isMobile ? "0.9em" : "0.8em"} mt="-4px">
                <HStack>
                  <Text color="gray.600">Already have an account?</Text>
                  <ChakraLink
                    color="teal.600"
                    fontWeight="bold"
                    as={RouterLink}
                    to="/login"
                    replace
                  >
                    Log in
                  </ChakraLink>
                </HStack>
              </VStack>
            </VStack>
          </form>
        )}
      </VStack>
    </VStack>
  );
};

export default CompleteRegistrationPage;
