import { ProtectedAPI } from "../../../api";
import { MenuItemSubstitution, MenuItemUpdate } from "../types/state";
import {
  ExtendedBranchDetails,
  ExtendedMenuDetails,
  ExtendedMenuItem,
} from "../types/forms";

export const handleUpdateBranch = async (
  uid: string,
  branch: ExtendedBranchDetails
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("name", branch.name);
    formData.append("cuisine", branch.cuisine);
    formData.append("phone_number", branch.phoneNumber);

    formData.append("description", branch.description);
    formData.append("allergen_message", branch.allergenMessage);

    formData.append("street_address", branch.streetAddress);
    formData.append("city", branch.city);
    formData.append("state", branch.state);
    formData.append("zipcode", branch.zipcode);

    formData.append("is_published", branch.isPublished.toString());

    formData.append(
      "selected_menus_uids",
      JSON.stringify(branch.selectedMenusUids)
    );

    if (branch.photoFile) {
      formData.append("photo_file", branch.photoFile);
    }

    if (branch.logoFile) {
      formData.append("logo_file", branch.logoFile);
    }

    const res = await ProtectedAPI.post("business/update_branch/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.status === 200) {
      return true;
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const handleUpdateMenu = async (
  uid: string,
  menu: ExtendedMenuDetails
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("name", menu.name);
    formData.append("allergen_message", menu.allergenMessage);
    formData.append("is_published", menu.isPublished.toString());
    if (menu.photoFile) {
      formData.append("photo_file", menu.photoFile);
    }

    const res = await ProtectedAPI.post("business/update_menu/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (res.status === 200) {
      return true;
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const handleUpdateMenuItemsBatch = async (
  updates: MenuItemUpdate[]
): Promise<boolean> => {
  try {
    const res = await ProtectedAPI.post("business/update_menu_items_batch/", {
      updates: updates.map((update) => ({
        uid: update.uid,
        section: update.section,
        order: update.order,
        is_published: update.isPublished,
      })),
    });

    if (res.status === 200) {
      return true;
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};

export const handleUpdateMenuItem = async (
  uid: string,
  menuItem: ExtendedMenuItem
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("name", menuItem.name);
    formData.append("description", menuItem.description);
    formData.append("section", menuItem.section);
    formData.append("price", menuItem.price.toString());
    formData.append(
      "cross_contact_description",
      menuItem.crossContactDescription
    );
    formData.append("allergens", JSON.stringify(menuItem.allergens));
    formData.append("diets", JSON.stringify(menuItem.diets));
    formData.append("is_published", menuItem.isPublished.toString());
    formData.append(
      "substitutions",
      JSON.stringify(
        menuItem.substitutions.map((sub: MenuItemSubstitution) => ({
          name: sub.name,
          description: sub.description,
          price: sub.price,
          allergens_added: sub.allergensAdded,
          allergens_replaced: sub.allergensReplaced,
        }))
      )
    );

    if (menuItem.photoFile) {
      formData.append("photo_file", menuItem.photoFile);
    }

    const res = await ProtectedAPI.post(
      "business/update_menu_item/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (res.status === 200) {
      return true;
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error);
  }

  return false;
};
