import React, { useEffect, useRef } from "react";
import { Button, HStack, Icon, Link, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { handleGetBranchDetails } from "../../features/business/services/get";
import { RestaurantBranchDetails } from "../../features/business/types/state";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

import { BranchFormRef } from "../../types/state";
import BranchForm from "../../features/business/components/BranchForm";
import { useGlobalContext } from "../../context/globalContext";

interface Props {}

const BusinessBranchEditPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const { isMobile } = useGlobalContext();
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [updateLoading, setUpdateloading] = React.useState<boolean>(false);

  const [branchNotFound, setBranchNotFound] = React.useState<boolean>(false);
  const branchFormRef = useRef<BranchFormRef>(null);
  const [currentBranch, setCurrentBranch] = React.useState<RestaurantBranchDetails | null>(null);

  const handleSubmit = () => {
    branchFormRef.current?.submitForm();
  };

  useEffect(() => {
    if (currentBranch) return;
    const getBranch = async () => {
      if (!uid) return;
      setLoading(true);
      const branch = await handleGetBranchDetails(uid);
      setLoading(false);
      if (branch) {
        setCurrentBranch(branch);
      } else {
        setBranchNotFound(true);
      }
    };

    getBranch();
  }, [currentBranch, uid]);

  let content;
  if (branchNotFound || (!loading && !currentBranch)) {
    content = (
      <VStack w="100%" h="100%" align="center" justify="center" color="gray.400" spacing={4} p={2}>
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Branch not found</Text>
      </VStack>
    );
  } else if (loading || !currentBranch) {
    content = (
      <VStack w="100%" h="100%" align="center" justify="center" p={2}>
        <Spinner size="lg" color="teal.600" />
      </VStack>
    );
  } else if (currentBranch) {
    content = (
      <>
        <BranchForm
          ref={branchFormRef}
          branch={currentBranch ? currentBranch : undefined}
          setDisabled={setDisabled}
          setLoading={setUpdateloading}
        />
      </>
    );
  } else {
    content = (
      <VStack w="100%" h="100%" align="center" justify="center" color="gray.400" spacing={4} p={2}>
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Something went wrong</Text>
      </VStack>
    );
  }

  return (
    <VStack align="flex-start" w="100%" maxW="860px">
      <HStack
        w="100%"
        maxW="800px"
        align="flex-start"
        justify="space-between"
        mb={isMobile ? "10px" : "20px"}
      >
        <HStack wrap="wrap">
          <Text
            color="teal.600"
            fontSize="1.2em"
            fontWeight="normal"
            mb="20px"
            cursor="pointer"
            onClick={() => {
              navigate("/business/locations");
            }}
          >
            Locations {"→"}
          </Text>
          <Text color="teal.600" fontSize="1.2em" fontWeight="bold" mb="20px">
            Edit location
          </Text>
        </HStack>
        {currentBranch && (
          <Stack direction={["column", "row", "row"]}>
            <Link href={currentBranch.qrCodeLink} isExternal>
              <Button colorScheme="gray">View QR code</Button>
            </Link>
            <Button
              colorScheme="teal"
              isDisabled={disabled}
              isLoading={updateLoading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Update location
            </Button>
          </Stack>
        )}
      </HStack>
      <VStack w="100%" h="100%" align="flex-start">
        {content}
      </VStack>
    </VStack>
  );
};

export default BusinessBranchEditPage;
