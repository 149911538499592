import React, { useEffect } from "react";
import { As, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import {
  HiCog6Tooth,
  HiOutlineChartPie,
  HiOutlineClipboard,
  HiOutlineBuildingStorefront,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuthContext } from "../../authentication/context/authContext";
import { useBusinessContext } from "../context/businessContext";
import { useGlobalContext } from "../../../context/globalContext";

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const { account } = useAuthContext();
  const { isMobileOrTablet } = useGlobalContext();
  const { sidebarOpen, setSidebarOpen } = useBusinessContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSidebarOpen(!isMobileOrTablet);
  }, [isMobileOrTablet, setSidebarOpen]);

  return (
    <VStack
      id="sidebar"
      justify="space-between"
      position="fixed"
      left={sidebarOpen ? "0" : "-280px"}
      top="48px"
      bg="white"
      shadow="lg"
      w="280px"
      h="calc(100vh - 48px)"
      transition="left 0.3s ease-in-out"
      overflowY="auto"
      overflowX="hidden"
      zIndex={9}
      spacing={0}
    >
      <VStack align="flex-start" w="100%" spacing={0}>
        <Flex
          w="100%"
          p={4}
          justify="flex-start"
          borderBottom="1px solid"
          borderColor="gray.200"
        >
          <Text color="gray.600" fontSize="1.2em" fontWeight="bold">
            {account?.organization.name}
          </Text>
        </Flex>
        <NavItem
          icon={HiOutlineChartPie}
          text="Dashboard"
          active={location.pathname === "/business/dashboard"}
          onClick={() => {
            navigate("/business/dashboard", { replace: true });
          }}
        />
        <NavItem
          icon={HiOutlineBuildingStorefront}
          text="Locations"
          active={location.pathname.startsWith("/business/locations")}
          onClick={() => {
            navigate("/business/locations", { replace: true });
          }}
        />
        <NavItem
          icon={HiOutlineClipboard}
          text="Menus"
          active={location.pathname.startsWith("/business/menus")}
          onClick={() => {
            navigate("/business/menus", { replace: true });
          }}
        />
        <NavItem
          icon={HiCog6Tooth}
          text="Preferences"
          active={location.pathname === "/business/preferences"}
          onClick={() => {
            navigate("/business/preferences", { replace: true });
          }}
        />
      </VStack>
      <VStack>
        <Text></Text>
      </VStack>
    </VStack>
  );
};

interface NavProps {
  icon: As;
  text: string;
  active: boolean;
  onClick: () => void;
}

const NavItem: React.FC<NavProps> = ({ icon, text, active, onClick }) => {
  return (
    <Flex
      w="100%"
      px={4}
      py={2}
      color="gray.500"
      align="center"
      cursor="pointer"
      justify="flex-start"
      borderBottom="1px solid"
      borderColor="gray.200"
      bg={active ? "gray.100" : "transparent"}
      onClick={onClick}
    >
      <Icon as={icon} fontSize="1.4em" color="gray.600" />
      <Text ps="10px">{text}</Text>
    </Flex>
  );
};

export default Sidebar;
