import { ProtectedAPI } from "../../../api";
import {
  RestaurantBranchOverview,
  RestaurantBranchDetails,
  MenuOverview,
  MenuDetails,
  MenuItem,
  MenuItemSubstitution,
  MetricsResults,
  MetricsMenuItem,
  MetricsAllergen,
} from "../types/state";

export const handleGetBranches = async (): Promise<RestaurantBranchOverview[]> => {
  try {
    const res = await ProtectedAPI.get("business/get_branches");
    if (res.status === 200) {
      const data = res.data.results;
      const branches = data.map((branch: any) => {
        return {
          datetimeCreated: new Date(branch.datetime_created),
          uid: branch.uid,
          name: branch.name,
          cuisine: branch.cuisine,
          photoHref: branch.photo_href,
          logoHref: branch.logo_href,
          allergenMessage: branch.allergen_message,
          isPublished: branch.is_published,
        } as RestaurantBranchOverview;
      });

      return branches;
    }
  } catch (error) {
    console.log(error);
  }

  return [];
};

export const handleGetBranchDetails = async (
  uid: string
): Promise<RestaurantBranchDetails | null> => {
  try {
    const res = await ProtectedAPI.get(`business/get_branches/${uid}`);
    if (res.status === 200) {
      const data = res.data;
      const branch = {
        datetimeCreated: new Date(data.datetime_created),
        datetimeUpdated: new Date(data.datetime_updated),
        uid: data.uid,
        name: data.name,
        cuisine: data.cuisine,
        phoneNumber: data.phone_number,
        description: data.description,
        allergenMessage: data.allergen_message,
        isPublished: data.is_published,
        photoHref: data.photo_href,
        logoHref: data.logo_href,
        qrCodeLink: data.qr_code_link,
        streetAddress: data.address.street_address,
        city: data.address.city,
        state: data.address.state,
        zipcode: data.address.zipcode,
        menus: data.menus.map((menu: any) => {
          return {
            datetimeCreated: new Date(menu.datetime_created),
            datetimeUpdated: new Date(menu.datetime_updated),
            uid: menu.uid,
            name: menu.name,
            photoHref: menu.photo_href,
            isPublished: menu.is_published,
            isAssigned: menu.is_assigned,
          } as MenuOverview;
        }),
      } as RestaurantBranchDetails;

      return branch;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const handleGetMenus = async (): Promise<MenuOverview[]> => {
  try {
    const res = await ProtectedAPI.get("business/get_menus");
    if (res.status === 200) {
      const data = res.data.results;
      const menus = data.map((menu: any) => {
        return {
          datetimeCreated: new Date(menu.datetime_created),
          datetimeUpdated: new Date(menu.datetime_updated),
          uid: menu.uid,
          name: menu.name,
          photoHref: menu.photo_href,
          isPublished: menu.is_published,
        } as MenuOverview;
      });

      return menus;
    }
  } catch (error) {
    console.log(error);
  }

  return [];
};

export const handleGetMenuDetails = async (uid: string): Promise<MenuDetails | null> => {
  try {
    const res = await ProtectedAPI.get(`business/get_menus/${uid}`);
    if (res.status === 200) {
      const data = res.data;
      const menu = {
        datetimeCreated: new Date(data.datetime_created),
        uid: data.uid,
        name: data.name,
        photoHref: data.photo_href,
        isPublished: data.is_published,
        allergenMessage: data.allergen_message,
        menuItems: data.menu_items.map((item: any) => {
          return {
            uid: item.uid,
            name: item.name,
            description: item.description,
            section: item.section,
            order: item.order,
            price: item.price,
            photoHref: item.photo_href,
            crossContactDescription: item.cross_contact_description,
            allergens: item.allergens || [],
            diets: item.diets || [],
            isPublished: item.is_published,
            substitutions: item.substitutions.map(
              (sub: any) =>
                ({
                  uid: sub.uid,
                  name: sub.name,
                  description: sub.description,
                  price: sub.price,
                  allergensAdded: sub.allergens_added,
                  allergensReplaced: sub.allergens_replaced,
                } as MenuItemSubstitution)
            ),
          } as MenuItem;
        }),
      } as MenuDetails;

      return menu;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const handleGetMetrics = async (): Promise<MetricsResults | null> => {
  try {
    const res = await ProtectedAPI.get("business/get_metrics");
    if (res.status === 200) {
      const data = res.data;

      return {
        qrCodeCount: data.qr_code_count,
        menuItemClickCount: data.menu_item_click_count,
        mostPopularMenuItems: data.most_popular_menu_items.map((item: any) => {
          return {
            name: item.name,
            count: item.count,
          } as MetricsMenuItem;
        }),
        mostCommonAllergens: data.most_common_allergens.map((allergen: any) => {
          return {
            name: allergen.name,
            count: allergen.count,
          } as MetricsAllergen;
        }),
      } as MetricsResults;
    } else {
      console.log(res);
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};
