import { ProtectedAPI } from "../../../api";

export const handleCreateSetupIntent = async (): Promise<string | null> => {
  try {
    const res = await ProtectedAPI.post("account/create_setup_intent/");
    if (res.status === 200) {
      const { client_secret } = res.data;
      return client_secret;
    } else {
      console.error("Failed to create setup intent");
    }
  } catch (error) {
    console.error(error);
  }

  return null;
};
