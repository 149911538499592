import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Flex, Modal, ModalOverlay, VStack } from "@chakra-ui/react";

import { isTokenValid } from "../features/authentication/utils/tokens";
import { useAuthContext } from "../features/authentication/context/authContext";
import { useGlobalContext } from "../context/globalContext";

import HeaderCustomer from "../features/customer/components/HeaderCustomer";

interface Props {}

const LayoutAdmin: React.FC<Props> = () => {
  const { account, token, clearAuth } = useAuthContext();
  const { modalLoading } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isTokenValid(token) || !account) {
      clearAuth();
      navigate("/login", { replace: true });
    } else if (account.userType !== "admin") {
      navigate("/", { replace: true });
    }
  }, [account, token, clearAuth, navigate]);

  return (
    <VStack minH="100%" w="100%" spacing={0}>
      <Modal isOpen={modalLoading} onClose={() => {}}>
        <ModalOverlay zIndex={999} backdropFilter="blur(2px)" />
      </Modal>
      <HeaderCustomer />
      <Flex flex={1} w="100%">
        <Outlet />
      </Flex>
    </VStack>
  );
};

export default LayoutAdmin;
