import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { isTokenValid } from "../features/authentication/utils/tokens";
import { useAuthContext } from "../features/authentication/context/authContext";

interface Props {}

const IndexPage: React.FC<Props> = () => {
  const { account, token, clearAuth } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!account || !isTokenValid(token)) {
      clearAuth();
      navigate("/login", { replace: true });
      return;
    }

    switch (account.userType) {
      case "admin":
        navigate("/admin/analytics", { replace: true });
        break;
      case "business":
        navigate("/business/dashboard", { replace: true });
        break;
      case "customer":
        navigate("/search", { replace: true });
        break;
      default:
        navigate("/error", { replace: true });
        break;
    }
  }, [account, token, clearAuth, navigate]);

  return null;
};

export default IndexPage;
