import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Flex,
  HStack,
  Heading,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";

import { isEmailValid } from "../utils/text";
import { useGlobalContext } from "../context/globalContext";
import {
  handleResetPasswordStart,
  handleResetPasswordConfirm,
} from "../features/authentication/services/password";
import { ResetPasswordForm } from "../features/authentication/types/forms";

interface Props {}

const ResetPasswordPage: React.FC<Props> = () => {
  const { isMobile } = useGlobalContext();
  const [step, setStep] = useState("email");

  const form = useFormik({
    initialValues: {
      email: "",
      code: "",
      password: "",
    } as ResetPasswordForm,
    validate: (values) => {},
    onSubmit: async (values) => {},
  });

  let formComponent;
  switch (step) {
    case "email":
      formComponent = <EmailForm form={form} setStep={setStep} />;
      break;
    case "code":
      formComponent = (
        <CodeForm form={form} isMobile={isMobile} setStep={setStep} />
      );
      break;
    default:
      formComponent = <EmailForm form={form} setStep={setStep} />;
  }

  return (
    <VStack
      flex={1}
      w="100%"
      align="center"
      justify={isMobile ? "flex-start" : "center"}
    >
      <form style={{ width: "100%", maxWidth: "360px" }}>
        <VStack align="flex-start" spacing={2}>
          <Heading color="teal.600" size="md" mb="10px">
            Reset password
          </Heading>

          {formComponent}

          <VStack
            align="flex-start"
            fontSize={isMobile ? "0.9em" : "0.8em"}
            mt="-4px"
          >
            <HStack>
              <Text color="gray.600">Remembered your password?</Text>
              <ChakraLink
                color="teal.600"
                fontWeight="bold"
                as={RouterLink}
                to="/login"
                replace
              >
                Log in
              </ChakraLink>
            </HStack>
            <HStack>
              <Text color="gray.600">Don't have an account?</Text>
              <ChakraLink
                color="teal.600"
                fontWeight="bold"
                as={RouterLink}
                to="/register"
                replace
              >
                Sign up
              </ChakraLink>
            </HStack>
          </VStack>
        </VStack>
      </form>
    </VStack>
  );
};

interface EmailProps {
  form: any;
  setStep: React.Dispatch<React.SetStateAction<string>>;
}

const EmailForm: React.FC<EmailProps> = ({ form, setStep }) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { isMobile, setModalLoading } = useGlobalContext();

  useEffect(() => {
    setError(null);
    setDisabled(!isEmailValid(form.values.email));
  }, [form.values, setDisabled]);

  return (
    <>
      <Input
        name="email"
        type="email"
        placeholder="Email"
        size={isMobile ? "lg" : "md"}
        value={form.values.email}
        onChange={form.handleChange}
      />
      <Button
        colorScheme="teal"
        w="100%"
        size={isMobile ? "lg" : "md"}
        isDisabled={disabled || loading}
        isLoading={loading}
        onClick={async () => {
          setModalLoading(true);
          setLoading(true);
          const resetSuccess = await handleResetPasswordStart(
            form.values.email
          );
          setLoading(false);
          setModalLoading(false);
          if (resetSuccess) {
            setStep("code");
          } else {
            setError(
              "Password cannot be reset. Make sure email has been verified."
            );
          }
        }}
      >
        Continue
      </Button>
      <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
        <Fade in={error ? true : false}>
          <Box w="100%" color="red.700" fontSize="0.8em" textAlign="end">
            {error}
          </Box>
        </Fade>
      </Flex>
    </>
  );
};

interface CodeProps {
  form: any;
  isMobile: boolean;
  setStep: React.Dispatch<React.SetStateAction<string>>;
}

const CodeForm: React.FC<CodeProps> = ({ form, isMobile, setStep }) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setModalLoading } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    setError(null);
    const { code, password } = form.values;
    setDisabled(code.length < 4 || password.length < 7 || password.length > 50);
  }, [form.values, setError, setDisabled]);

  return (
    <>
      <Text color="gray.600" fontSize="0.8em">
        Enter the code that was sent to your email. If you didn't receive a
        code, this may be because you have not verified your email.
      </Text>
      <Input
        name="code"
        type="text"
        placeholder="Code"
        size={isMobile ? "lg" : "md"}
        value={form.values.code}
        onChange={form.handleChange}
      />
      <Input
        name="password"
        type="password"
        placeholder="New password"
        size={isMobile ? "lg" : "md"}
        value={form.values.password}
        onChange={form.handleChange}
      />
      <HStack w="100%" justify="space-between">
        <Button
          colorScheme="gray"
          isDisabled={loading}
          size={isMobile ? "lg" : "md"}
          onClick={() => {
            form.setValues({ code: "", password: "" });
            setStep("email");
          }}
        >
          Back
        </Button>
        <Button
          colorScheme="teal"
          isDisabled={disabled || loading}
          isLoading={loading}
          size={isMobile ? "lg" : "md"}
          onClick={async () => {
            setModalLoading(true);
            setLoading(true);

            const { email, code, password } = form.values;
            const resetSuccess = await handleResetPasswordConfirm(
              email,
              code,
              password
            );
            setLoading(false);
            setModalLoading(false);
            if (resetSuccess) {
              navigate("/login", { replace: true });
            } else {
              setError("Password reset failed with provided code.");
            }
          }}
        >
          Reset password
        </Button>
      </HStack>

      <Flex h="20px" w="100%" justify="flex-end" mt="-4px">
        <Fade in={error ? true : false}>
          <Box w="100%" color="red.700" fontSize="0.8em" textAlign="end">
            {error}
          </Box>
        </Fade>
      </Flex>
    </>
  );
};

export default ResetPasswordPage;
