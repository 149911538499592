import React, { useState } from "react";
import { Button, Flex, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { HiArrowRight } from "react-icons/hi2";

import { useGlobalContext } from "../../../context/globalContext";

// HOW TO SETUP CUSTOMER - https://stripe.com/docs/js/setup_intents/confirm_setup

interface RegisterPaymentProps {
  form: any;
  onContinue: () => void;
}

const RegisterPaymentForm: React.FC<RegisterPaymentProps> = ({
  form,
  onContinue,
}) => {
  const [paymentDisabled, setPaymentDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { setModalLoading } = useGlobalContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setModalLoading(true);
    setLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_APP_URL}/get-started`,
      },
    });

    if (error) {
      console.log(error);
    }

    setLoading(false);
    setModalLoading(false);
  };

  return (
    <Flex direction="column" w="100%">
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <PaymentElement
          onChange={(e) => {
            setPaymentDisabled(!e.complete);
          }}
          options={{}}
        />

        <VStack w="100%" align="flex-end" mt="20px">
          <Button
            id="submit"
            type="submit"
            variant="solid"
            colorScheme="teal"
            isDisabled={paymentDisabled}
            isLoading={loading}
          >
            Add payment method
          </Button>
          <HStack
            color="gray.400"
            fontSize="0.9em"
            cursor="pointer"
            _hover={{ color: "gray.600" }}
            onClick={() => {
              onContinue();
            }}
          >
            <Text>Skip</Text>
            <Icon as={HiArrowRight} />
          </HStack>
        </VStack>
      </form>
    </Flex>
  );
};

export default RegisterPaymentForm;
