// allergens
import corn from "../assets/icons/corn.png";
import dairy from "../assets/icons/dairy.png";
import egg from "../assets/icons/egg.png";
import fish from "../assets/icons/fish.png";
import peanut from "../assets/icons/peanut.png";
import sesame from "../assets/icons/sesame.png";
import shellfish from "../assets/icons/shellfish.png";
import soy from "../assets/icons/soy.png";
import sulfite_sulphite from "../assets/icons/sulfite-sulphite.png";
import tree_nuts from "../assets/icons/tree-nuts.png";
import wheat_gluten from "../assets/icons/wheat-gluten.png";
import { ItemConfig } from "../types/state";

// dietary preferences
import pescatarian from "../assets/icons/pescatarian.png";
import vegan from "../assets/icons/vegan.png";
import vegetarian from "../assets/icons/vegetarian.png";

// customer facing allergens - no meat or fish
export const ALLERGENS_CUSTOMER_CONFIG: ItemConfig[] = [
  { icon: egg, name: "egg", label: "Egg" },
  { icon: peanut, name: "peanut", label: "Peanut" },
  { icon: fish, name: "fish", label: "Fish" },
  { icon: dairy, name: "dairy", label: "Dairy" },
  { icon: soy, name: "soy", label: "Soy" },
  { icon: shellfish, name: "shellfish", label: "Shellfish" },
  { icon: wheat_gluten, name: "wheat_gluten", label: "Wheat / Gluten" },
  { icon: tree_nuts, name: "tree_nuts", label: "Tree nuts" },
  {
    icon: sulfite_sulphite,
    name: "sulfite_sulphite",
    label: "Sulfite / Sulphite",
  },
  { icon: sesame, name: "sesame", label: "Sesame" },
  { icon: corn, name: "corn", label: "Corn" },
];

// restaurant facing allergens - with meat and fish
export const ALLERGENS_RESTAURANT_CONFIG = [...ALLERGENS_CUSTOMER_CONFIG];
ALLERGENS_RESTAURANT_CONFIG.push({
  icon: vegetarian,
  name: "meat",
  label: "Meat",
});
ALLERGENS_RESTAURANT_CONFIG.push({
  icon: pescatarian,
  name: "fish",
  label: "Seafood",
});

export const DIETS_CONFIG: ItemConfig[] = [
  { icon: pescatarian, name: "pescatarian", label: "Pescatarian" },
  { icon: vegan, name: "vegan", label: "Vegan" },
  { icon: vegetarian, name: "vegetarian", label: "Vegetarian" },
];

export const ACCOUNT_KEY = "account";
export const TOKEN_KEY = "token";

export const ACCESS_TOKEN_EXPIRES: number = parseInt(
  process.env.REACT_APP_ACCESS_TOKEN_EXPIRES_MILLIS || "3600000",
  10
);

export const REFRESH_TOKEN_EXPIRES: number = parseInt(
  process.env.REACT_APP_REFRESH_TOKEN_EXPIRES_MILLIS || "2592000000",
  10
);

export const REGISTER_BIZ_FLOW_ACTIVE = "registerBizFlowActive";
export const REGISTER_CUST_FLOW_ACTIVE = "registerCustFlowActive";
export const LOGIN_FLOW_ACTIVE = "loginFlowActive";
