import React from "react";
import { Flex, List, ListItem, Spinner, Text, VStack, HStack } from "@chakra-ui/react";

import { useBusinessContext } from "../../features/business/context/businessContext";

interface Props {}

const BusinessDashboardPage: React.FC<Props> = () => {
  const { metrics, loadingMetrics } = useBusinessContext();

  if (loadingMetrics) {
    return (
      <VStack w="100%" h="100%" align="center" justify="center">
        <Spinner size="lg" color="teal.600" />
      </VStack>
    );
  }

  if (!metrics) {
    return (
      <VStack w="100%" h="100%" align="center" justify="center" color="gray.400" spacing={4}>
        <Text fontSize="1.2em">No metrics found</Text>
      </VStack>
    );
  }

  return (
    <VStack align="flex-start">
      <Text color="teal.600" fontSize="1.2em" fontWeight="bold" mb="20px">
        Activity from the last 30 days
      </Text>
      <Flex w="100%" gap="12px" wrap="wrap">
        <VStack align="flex-start" spacing={1}>
          <Text color="gray.600" fontWeight="semibold">
            QR Code Scans
          </Text>
          <Text color="gray.500" fontSize="0.8em">
            Number menu QR code scans
          </Text>
          <MetricBox>
            <Text color="green.600" fontSize="4.2em">
              {metrics.qrCodeCount}
            </Text>
          </MetricBox>
        </VStack>
        <VStack align="flex-start" spacing={1}>
          <Text color="gray.600" fontWeight="semibold">
            Menu item click count
          </Text>
          <Text color="gray.500" fontSize="0.8em">
            Number of clicks within a menu
          </Text>
          <MetricBox>
            <Text color="blue.600" fontSize="4.2em">
              {metrics.menuItemClickCount}
            </Text>
          </MetricBox>
        </VStack>
        <VStack align="flex-start" spacing={1}>
          <Text color="gray.600" fontWeight="semibold">
            Most popular menu items
          </Text>
          <Text color="gray.500" fontSize="0.8em">
            Ranked by number of clicks
          </Text>
          <MetricBox>
            <List spacing={3}>
              {metrics.mostPopularMenuItems.length > 0 ? (
                metrics.mostPopularMenuItems.map((item) => (
                  <ListItem key={item.name}>
                    <HStack>
                      <Text color="gray.600" fontSize="1em" fontWeight="bold">
                        {item.count}
                      </Text>
                      <Text color="gray.600" fontSize="1em">
                        {item.name}
                      </Text>
                    </HStack>
                  </ListItem>
                ))
              ) : (
                <Text color="gray.400">No data yet</Text>
              )}
            </List>
          </MetricBox>
        </VStack>
        <VStack align="flex-start" spacing={1}>
          <Text color="gray.600" fontWeight="semibold">
            Most common allergens
          </Text>
          <Text color="gray.500" fontSize="0.8em">
            Ranked by number of customers
          </Text>
          <MetricBox>
            <List spacing={3}>
              {metrics.mostCommonAllergens.length > 0 ? (
                metrics.mostCommonAllergens.slice(0, 3).map((item) => (
                  <ListItem key={item.name}>
                    <HStack>
                      <Text color="gray.600" fontSize="1em" fontWeight="bold">
                        {item.count}
                      </Text>
                      <Text color="gray.600" fontSize="1em">
                        {item.name}
                      </Text>
                    </HStack>
                  </ListItem>
                ))
              ) : (
                <Text color="gray.400">No data yet</Text>
              )}
            </List>
          </MetricBox>
        </VStack>
      </Flex>
    </VStack>
  );
};

const MetricBox: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Flex
      w="240px"
      h="240px"
      align="center"
      justify="center"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.100"
      shadow="md"
    >
      {children}
    </Flex>
  );
};

export default BusinessDashboardPage;
