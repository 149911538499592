import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { HiOutlineUser, HiOutlineArrowRightOnRectangle } from "react-icons/hi2";

import Logo from "../../../assets/images/logo.png";
import { useAuthContext } from "../../authentication/context/authContext";
import { useGlobalContext } from "../../../context/globalContext";

interface Props {}

const HeaderCustomer: React.FC<Props> = () => {
  const { clearAuth } = useAuthContext();
  const { isMobile, setModalLoading } = useGlobalContext();

  return (
    <Flex
      align="center"
      justify="space-between"
      h="48px"
      w="100%"
      zIndex={99}
      shadow="md"
      px={isMobile ? 2 : 4}
    >
      <ChakraLink h="80%" as={RouterLink} to="/" replace={true}>
        <Box h="100%">
          <Image h="100%" src={Logo} alt="logo" />
        </Box>
      </ChakraLink>
      <Flex align="center" h="100%">
        <Menu gutter={0}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HiOutlineUser />}
            variant="ghost"
            color="gray.600"
            fontSize={isMobile ? "1.8em" : "1.6em"}
            _active={{ bg: "transparent" }}
          />
          <MenuList>
            <ChakraLink as={RouterLink} to="/settings">
              <MenuItem
                color="gray.600"
                icon={<HiOutlineUser fontSize={isMobile ? "1.6em" : "1.4em"} />}
              >
                Settings
              </MenuItem>
            </ChakraLink>
            <MenuItem
              color="gray.600"
              icon={
                <HiOutlineArrowRightOnRectangle
                  fontSize={isMobile ? "1.6em" : "1.4em"}
                />
              }
              onClick={() => {
                setModalLoading(true);
                setTimeout(() => {
                  clearAuth();
                  localStorage.clear();
                  setModalLoading(false);
                }, 1200);
              }}
            >
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default HeaderCustomer;
