import { ProtectedAPI } from "../../../api";
import {
  City,
  RestaurantAddress,
  RestaurantBranch,
  RestaurantMenu,
  RestaurantMenuDetailsParams,
  RestaurantMenuItem,
  MenuItemSubstitution,
  RestaurantMenuSection,
  RestaurantSearchParams,
} from "../types/state";

export const handleGetCities = async (): Promise<City[] | null> => {
  try {
    const res = await ProtectedAPI.get("customer/cities");
    if (res.status === 200) {
      return res.data.results.map((city: any) => {
        return {
          city: city.city,
          state: city.state,
          label: city.label,
        } as City;
      });
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const handleGetBranchDetails = async (
  uid: string
): Promise<RestaurantBranch | null> => {
  try {
    const res = await ProtectedAPI.get(`customer/branches/${uid}`);
    if (res.status === 200) {
      const data = res.data;
      const { address, menus } = data;
      return {
        datetimeCreated: new Date(data.datetime_created),
        uid: data.uid,
        name: data.name,
        cuisine: data.cuisine,
        phoneNumber: data.phone_number,
        description: data.description,
        photoHref: data.photo_href,
        logoHref: data.logo_href,
        googleMapsLink: data.google_maps_link,
        uberEatsLink: data.uber_eats_link,
        grubHubLink: data.grubhub_link,
        doordashLink: data.doordash_link,
        address: {
          street: address.street_address,
          city: address.city,
          state: address.state,
          zipcode: address.zipcode,
        } as RestaurantAddress,
        menus: menus.map((menu: any) => {
          return {
            uid: menu.uid,
            name: menu.name,
            photoHref: menu.photo_href,
          } as RestaurantMenu;
        }),
      } as RestaurantBranch;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const handleGetMenuDetails = async (
  branchUid: string,
  menuUid: string,
  params: RestaurantMenuDetailsParams
): Promise<RestaurantMenu | null> => {
  try {
    const res = await ProtectedAPI.get(
      `customer/branches/${branchUid}/menus/${menuUid}`,
      {
        params: {
          allergens: params.allergens.join(","),
          diets: params.diets.join(","),
        },
      }
    );
    if (res.status === 200) {
      const menu = res.data;
      return {
        uid: menu.uid,
        name: menu.name,
        photoHref: menu.photo_href,
        sections: Object.keys(menu.sections).map(
          (key) =>
            ({
              name: key,
              items: menu.sections[key].map(
                (item: any) =>
                  ({
                    uid: item.uid,
                    name: item.name,
                    description: item.description,
                    section: item.section,
                    price: item.price,
                    photoHref: item.photo_href,
                    crossContactDescription: item.cross_contact_description,
                    allergens: item.allergens,
                    diets: item.diets,
                    requiresSubstitution: item.requires_substitution,
                    substitutions: item.substitutions.map(
                      (sub: any) =>
                        ({
                          uid: sub.uid,
                          name: sub.name,
                          description: sub.description,
                          price: sub.price,
                          allergensAdded: sub.allergens_added,
                          allergensReplaced: sub.allergens_replaced,
                        } as MenuItemSubstitution)
                    ),
                  } as RestaurantMenuItem)
              ),
            } as RestaurantMenuSection)
        ),
      } as RestaurantMenu;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};

export const handleSearchBranches = async (
  params: RestaurantSearchParams
): Promise<RestaurantBranch[] | null> => {
  try {
    const res = await ProtectedAPI.get("customer/branches", {
      params: {
        allergens: params.allergens.join(","),
        diets: params.diets.join(","),
        keywords: params.keywords,
        percent_match: params.matchPercent,
        cities: JSON.stringify(
          params.cities?.map((city) => ({
            city: city.city,
            state: city.state,
          }))
        ),
      },
    });
    if (res.status === 200) {
      const data = res.data.results;
      const branches = data.map((branch: any) => {
        const { address } = branch;
        return {
          datetimeCreated: new Date(branch.datetime_created),
          uid: branch.uid,
          name: branch.name,
          cuisine: branch.cuisine,
          phoneNumber: branch.phone_number,
          description: branch.description,
          photoHref: branch.photo_href,
          logoHref: branch.logo_href,
          googleMapsLink: branch.google_maps_link,
          percentWithoutAllergens: branch.percent_without_allergens,
          address: {
            street: address.street_address,
            city: address.city,
            state: address.state,
            zipcode: address.zipcode,
          },
        } as RestaurantBranch;
      });

      return branches;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};
