import axios from "axios";

import { TOKEN_KEY } from "../data/constants";
import { Token } from "../features/authentication/types/state";
import { handleRefreshAccessToken } from "../features/authentication/services/tokens";

export const PublicAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

export const ProtectedAPI = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

ProtectedAPI.interceptors.request.use(
  (config) => {
    const tokenStr = window.localStorage.getItem(TOKEN_KEY);
    if (tokenStr) {
      const token: Token = JSON.parse(tokenStr);
      if (config.headers) {
        config.headers["Authorization"] = "JWT " + token.accessToken;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ProtectedAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const token = await handleRefreshAccessToken();
      if (token) {
        axios.defaults.headers.common["Authorization"] =
          "JWT " + token.accessToken;
        return ProtectedAPI(originalRequest);
      } else {
        console.log("refresh token expired");
        window.localStorage.clear();
        window.location.href = "/login";
      }
    }

    return Promise.reject(error);
  }
);
