import React, { useEffect, useState } from "react";
import {
  HStack,
  Heading,
  Icon,
  Link as ChakraLink,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  HiArrowLeft,
  HiOutlineMapPin,
  HiOutlinePhone,
  HiOutlineExclamationTriangle,
} from "react-icons/hi2";

import { handleGetBranchDetails } from "../../features/customer/services/search";
import { useSearchContext } from "../../features/customer/context/searchContext";
import { useGlobalContext } from "../../context/globalContext";
import MenuCard from "../../components/MenuCard";
import BranchSubheader from "../../components/BranchSubheader";

interface Props {}

const RestaurantProfilePage: React.FC<Props> = () => {
  const theme = useTheme();
  const { isMobile } = useGlobalContext();
  const { currentBranch, setCurrentBranch } = useSearchContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [branchNotFound, setBranchNotFound] = useState<boolean>(false);
  const { uid } = useParams();

  useEffect(() => {
    const getBranch = async () => {
      if (!uid) return;
      setLoading(true);
      const branch = await handleGetBranchDetails(uid);
      setLoading(false);
      if (branch) {
        setCurrentBranch(branch);
      } else {
        setBranchNotFound(true);
      }
    };

    getBranch();
  }, [uid, setCurrentBranch]);

  if (branchNotFound || (!loading && !currentBranch)) {
    return (
      <VStack
        w="100%"
        align="center"
        justify="center"
        color="gray.400"
        spacing={4}
        p={2}
      >
        <Icon as={HiOutlineExclamationTriangle} fontSize="3.6em" />
        <Text fontSize="1.2em">Restaurant not found</Text>
        <ChakraLink as={RouterLink} to="/search" replace={true}>
          Go back to search
        </ChakraLink>
      </VStack>
    );
  }

  if (loading) {
    return (
      <VStack align="flex-start" w="100%" pb={8}>
        <VStack
          align="flex-start"
          w="100%"
          flex={1}
          m="0 auto"
          mt="20px"
          maxW={`${theme.breakpoints.md}`}
          spacing={4}
          px={4}
        >
          <HStack spacing={isMobile ? 4 : 8}>
            <SkeletonCircle size={isMobile ? "80px" : "100px"} />

            <VStack align="flex-start">
              <Skeleton isLoaded={!loading}>
                <Heading
                  fontSize={isMobile ? "1.2em" : "1.4em"}
                  color="primary.600"
                >
                  ---------------------------------------------
                </Heading>
              </Skeleton>
              <Skeleton isLoaded={!loading}>
                <Text fontSize={isMobile ? "1em" : "1.2em"} color="gray.600">
                  ---------------
                </Text>
              </Skeleton>
            </VStack>
          </HStack>

          <VStack align="flex-start" ms="8px">
            <Skeleton isLoaded={!loading}>
              <HStack color="gray.500" spacing={3}>
                <Icon as={HiOutlineMapPin} fontSize="1.6em" />
                <Text fontSize="1em">
                  ------------------------------------------------------
                </Text>
              </HStack>
            </Skeleton>

            <Skeleton isLoaded={!loading}>
              <HStack color="gray.500" spacing={3}>
                <Icon as={HiOutlinePhone} fontSize="1.6em" />
                <Text fontSize="1em">--------------</Text>
              </HStack>
            </Skeleton>
          </VStack>

          <VStack align="flex-start" w="100%" mt="10px">
            <Skeleton isLoaded={!loading}>
              <Text fontSize="1em" color="gray.600">
                ---------------------------------------------------------------------------------------------------------------
              </Text>
            </Skeleton>
          </VStack>
        </VStack>
      </VStack>
    );
  }

  if (currentBranch) {
    return (
      <VStack align="flex-start" w="100%" pb={8}>
        <VStack
          align="flex-start"
          w="100%"
          flex={1}
          m="0 auto"
          mt="20px"
          maxW={`${theme.breakpoints.md}`}
          spacing={4}
          px={4}
        >
          <ChakraLink as={RouterLink} to="/search">
            <HStack color="gray.400" ms="10px">
              <Icon as={HiArrowLeft} fontSize="1em" />
              <Text fontSize="1em">Back to search</Text>
            </HStack>
          </ChakraLink>
          <BranchSubheader branch={currentBranch} loading={loading} />

          <VStack
            align="flex-start"
            w="100%"
            mt={currentBranch.description ? "10px" : "0px"}
          >
            <Skeleton isLoaded={!loading}>
              <Text fontSize="1em" color="gray.600">
                {currentBranch.description}
              </Text>
            </Skeleton>
          </VStack>

          {!loading && currentBranch.menus && (
            <VStack spacing={4} align="flex-start" w="100%">
              <Heading color="primary.600" size="md">
                Menus
              </Heading>

              <SimpleGrid w="100%" columns={[1, 1, 2]} spacing={4}>
                {currentBranch.menus.map((menu, index) => (
                  <MenuCard
                    key={menu.uid}
                    menu={menu}
                    link={`/restaurants/${currentBranch.uid}/menus/${menu.uid}`}
                  />
                ))}
              </SimpleGrid>
            </VStack>
          )}
        </VStack>
      </VStack>
    );
  }

  return <div></div>;
};

export default RestaurantProfilePage;
