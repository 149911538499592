import { ProtectedAPI } from "../../../api";
import {
  UpdateBusinessAccountForm,
  UpdateCustomerAccountForm,
} from "../types/forms";

export const handleUpdateBusinessAccount = async (
  form: UpdateBusinessAccountForm,
  setError: (error: string) => void
): Promise<boolean> => {
  try {
    const updateSuccess = await ProtectedAPI.post(
      "account/update_business_account/",
      {
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        phone_number: form.phoneNumber,
        organization_name: form.organizationName,
      }
    );

    if (updateSuccess.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    setError("Unable to update account. Email may already exist.");
  }

  return false;
};

export const handleUpdateCustomerAccount = async (
  form: UpdateCustomerAccountForm,
  setError: (error: string) => void
): Promise<boolean> => {
  try {
    const updateSuccess = await ProtectedAPI.post(
      "account/update_customer_account/",
      {
        email: form.email,
        first_name: form.firstName,
        last_name: form.lastName,
        phone_number: form.phoneNumber,
        allergens: form.allergens,
        diets: form.diets,
      }
    );

    if (updateSuccess.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    setError("Unable to update account.");
  }

  return false;
};
