import React from "react";
import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link as ChakraLink,
  SkeletonCircle,
  SkeletonText,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  HiOutlineHeart,
  HiOutlineMapPin,
  HiOutlinePhone,
} from "react-icons/hi2";
import { Link as RouterLink } from "react-router-dom";

import { formatPhoneNumber } from "../../../utils/text";
import { useGlobalContext } from "../../../context/globalContext";
import { RestaurantBranch } from "../types/state";

interface Props {
  branch: RestaurantBranch;
}

const RestaurantCard: React.FC<Props> = ({ branch }) => {
  const { isMobile } = useGlobalContext();

  const {
    uid,
    name,
    cuisine,
    phoneNumber,
    address,
    logoHref,
    photoHref,
    googleMapsLink,
    percentWithoutAllergens,
  } = branch;

  let matchColor = "green";
  if (percentWithoutAllergens < 50) {
    matchColor = "orange";
  } else if (percentWithoutAllergens < 75) {
    matchColor = "yellow";
  }

  return (
    <VStack
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
      spacing={4}
      p={2}
      flex={isMobile ? "0 0 100%" : "0 0 48%"}
    >
      <HStack w="100%" align="flex-start" justify="space-between">
        <HStack spacing={4}>
          <Image
            borderRadius="full"
            boxSize="72px"
            src={logoHref}
            fallbackSrc={photoHref}
            alt="restaurant photo"
          />
          <VStack align="flex-start" spacing={0}>
            <Text color="gray.600">{name}</Text>
            <Text color="gray.400" fontSize="0.8em">
              {cuisine}
            </Text>

            {googleMapsLink && (
              <ChakraLink href={googleMapsLink} isExternal>
                <Text color="teal.600" fontSize="0.8em" fontWeight="bold">
                  Google Reviews
                </Text>
              </ChakraLink>
            )}
          </VStack>
        </HStack>
        <Flex>
          <Icon
            as={HiOutlineHeart}
            color="gray.400"
            fontSize="1.2em"
            cursor="pointer"
          />
        </Flex>
      </HStack>
      <HStack align="flex-end" justify="space-between" w="100%">
        <VStack align="flex-start" ms="8px">
          <HStack color="gray.500">
            <Icon as={HiOutlineMapPin} fontSize="1.2em" />
            <Text fontSize="0.9em">
              {address.city}, {address.state}
            </Text>
          </HStack>
          <HStack color="gray.500">
            <Icon as={HiOutlinePhone} fontSize="1.1em" />
            <Text fontSize="0.9em">{formatPhoneNumber(phoneNumber)}</Text>
          </HStack>
        </VStack>
        <VStack align="flex-end">
          <Tag colorScheme={matchColor} size="sm" variant="outline">
            {percentWithoutAllergens}% match
          </Tag>
          <ChakraLink as={RouterLink} to={`/restaurants/${uid}`}>
            <Button colorScheme="orange" size="sm">
              View more
            </Button>
          </ChakraLink>
        </VStack>
      </HStack>
    </VStack>
  );
};

interface SkeletonProps {}
export const RestaurantCardSkelton: React.FC<SkeletonProps> = () => {
  const { isMobile } = useGlobalContext();

  return (
    <VStack
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8px"
      spacing={8}
      p={2}
      flex={isMobile ? "0 0 100%" : "0 0 48%"}
    >
      <HStack w="100%" align="flex-start" justify="space-between">
        <HStack spacing={4}>
          <SkeletonCircle size="72px" />
          <VStack align="flex-start" spacing={0}>
            <SkeletonText
              noOfLines={3}
              spacing="2"
              skeletonHeight="3"
              w="120px"
            />
          </VStack>
        </HStack>
      </HStack>
      <HStack align="flex-end" justify="space-between" w="100%">
        <VStack align="flex-start" ms="8px">
          <SkeletonText
            noOfLines={2}
            spacing="2"
            skeletonHeight="4"
            w="160px"
          />
        </VStack>
      </HStack>
    </VStack>
  );
};

export default RestaurantCard;
