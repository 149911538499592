import { PublicAPI } from "../../../api";
import { ACCESS_TOKEN_EXPIRES, TOKEN_KEY } from "../../../data/constants";
import { Token } from "../types/state";

export const handleRefreshAccessToken = async (): Promise<Token | null> => {
  const tokenStr = localStorage.getItem(TOKEN_KEY);
  if (!tokenStr) return null;

  let token: Token = JSON.parse(tokenStr);

  try {
    const res = await PublicAPI.post("account/refresh/", {
      refresh_token: token.refreshToken,
    });

    if (res.status === 200) {
      token.accessToken = res.data.access_token;
      token.datetimeAccessCreated = new Date();
      token.accessExpiresIn = ACCESS_TOKEN_EXPIRES;
      localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
      return token;
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};
