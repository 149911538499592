import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { HiOutlinePlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { HiOutlineDocumentText } from "react-icons/hi2";

import { BranchFormRef } from "../../../types/state";
import SelectMenuCard from "../../../components/SelectMenuCard";
import { RestaurantBranchDetails } from "../types/state";
import { ExtendedBranchDetails } from "../types/forms";
import { handleCreateBranch } from "../services/create";
import { handleUpdateBranch } from "../services/update";
import { useGlobalContext } from "../../../context/globalContext";
import { useBusinessContext } from "../context/businessContext";
import StateSelect from "../../../components/StateSelect";

interface Props {
  branch?: RestaurantBranchDetails;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const BranchForm = forwardRef<BranchFormRef, Props>((props, ref) => {
  const { menus } = useBusinessContext();
  const [initialize, setInitialized] = useState(false);
  const { isMobile, isMobileOrTablet, setModalLoading } = useGlobalContext();
  const [photo, setPhoto] = useState<string | ArrayBuffer | null>(null);
  const [logo, setLogo] = useState<string | ArrayBuffer | null>(null);
  const navigate = useNavigate();

  const { branch, setDisabled, setLoading } = props;

  const form = useFormik({
    initialValues: {
      photoFile: null,
      photoHref: "",
      logoFile: null,
      logoHref: "",
      name: "",
      cuisine: "",
      phoneNumber: "",
      allergenMessage: "",
      selectedMenusUids: [""],
      streetAddress: "",
      city: "",
      state: "",
      zipcode: "",
      isPublished: true,
    } as ExtendedBranchDetails,
    validate: () => {},
    onSubmit: async (values) => {
      setDisabled(true);
      setLoading(true);
      setModalLoading(true);
      // edit mode
      if (branch && branch.uid) {
        const updateSuccess = await handleUpdateBranch(branch.uid, values);
        console.log("updateSuccess", updateSuccess);
        if (updateSuccess) {
          window.location.reload(); // fixme: this is a hack
        }
      }
      // create mode
      else {
        const branchUid = await handleCreateBranch(values);
        console.log("branchUid", branchUid);
        if (branchUid) {
          navigate(`/business/locations/${branchUid}`, { replace: true });
        }
      }

      setModalLoading(false);
      setLoading(false);
      setDisabled(false);
    },
  });

  useImperativeHandle(ref, () => ({
    async submitForm() {
      await form.submitForm();
    },
  }));

  useEffect(() => {
    if (!initialize && branch) {
      form.setFieldValue("photoHref", branch.photoHref);
      form.setFieldValue("logoHref", branch.logoHref);
      form.setFieldValue("name", branch.name);
      form.setFieldValue("cuisine", branch.cuisine);
      form.setFieldValue("phoneNumber", branch.phoneNumber);
      form.setFieldValue("description", branch.description);
      form.setFieldValue("allergenMessage", branch.allergenMessage);
      form.setFieldValue("streetAddress", branch.streetAddress);
      form.setFieldValue("city", branch.city);
      form.setFieldValue("state", branch.state);
      form.setFieldValue("zipcode", branch.zipcode);
      form.setFieldValue("isPublished", branch.isPublished);

      if (branch.menus) {
        const _selectedMenusUids = branch.menus
          .filter((menu) => menu.isAssigned)
          .map((menu) => menu.uid);
        form.setFieldValue("selectedMenusUids", _selectedMenusUids);
      }

      setInitialized(true);
    }
  }, [form, initialize, branch]);

  useEffect(() => {
    if (form.values.name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [form.values.name, setDisabled]);

  let photoContent;
  if (photo) {
    photoContent = (
      <Image
        src={photo as string}
        alt="branch photo"
        objectFit="cover"
        w="100%"
      />
    );
  } else if (form.values.photoHref) {
    photoContent = (
      <Image
        src={form.values.photoHref}
        alt="branch photo"
        objectFit="cover"
        w="100%"
      />
    );
  } else {
    photoContent = (
      <Flex w="100%" h="100%" align="center" justify="center" bg="gray.200">
        <Icon as={HiOutlinePlus} fontSize="1.1em" color="gray.500" />
        <Text color="gray.500" ms="4px">
          Add photo
        </Text>
      </Flex>
    );
  }

  let logoContent;
  if (logo) {
    logoContent = (
      <Image
        src={logo as string}
        alt="branch logo"
        objectFit="cover"
        w="100%"
      />
    );
  } else if (form.values.logoHref) {
    logoContent = (
      <Image
        src={form.values.logoHref}
        alt="branch logo"
        objectFit="cover"
        w="100%"
      />
    );
  } else {
    logoContent = (
      <Flex w="100%" h="100%" align="center" justify="center" bg="gray.200">
        <Icon as={HiOutlinePlus} fontSize="1.1em" color="gray.500" />
        <Text color="gray.500" ms="4px">
          Add logo
        </Text>
      </Flex>
    );
  }

  let menusContent;
  if (menus && menus.length > 0) {
    menusContent = (
      <Grid
        mt={isMobile ? "10px" : "20px"}
        w="100%"
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"}
        gap={4}
      >
        {menus.map((menu) => (
          <GridItem w="100%">
            <SelectMenuCard
              key={menu.uid}
              menu={menu}
              selected={form.values.selectedMenusUids.includes(menu.uid)}
              onClick={() => {
                console.log("clicked menu", menu.name);
                const _selectedMenusUids = form.values.selectedMenusUids;
                if (_selectedMenusUids.includes(menu.uid)) {
                  _selectedMenusUids.splice(
                    _selectedMenusUids.indexOf(menu.uid),
                    1
                  );
                } else {
                  _selectedMenusUids.push(menu.uid);
                }

                form.setFieldValue("selectedMenusUids", _selectedMenusUids);
              }}
            />
          </GridItem>
        ))}
      </Grid>
    );
  } else {
    menusContent = (
      <VStack
        w="100%"
        h="100%"
        align="center"
        justify="center"
        color="gray.400"
        spacing={4}
        p={2}
      >
        <Icon as={HiOutlineDocumentText} fontSize="3.6em" />
        <Text fontSize="1.2em">You don't have any menus yet.</Text>
      </VStack>
    );
  }

  return (
    <VStack align="flex-start" w="100%" maxW="800px" pb="40px" spacing={4}>
      <Stack
        minW={isMobileOrTablet ? "100%" : "auto"}
        direction={["column", "row", "row"]}
        spacing={4}
      >
        <VStack align="flex-start">
          <Text fontSize="0.8em" color="gray.500">
            Location photo
          </Text>
          <Flex
            cursor="pointer"
            borderRadius="md"
            overflow="hidden"
            bg="gray.100"
            w={isMobile ? "100%" : "320px"}
            minH="240px"
            maxH="320px"
            border="1px solid"
            borderColor="gray.200"
            onClick={() => {
              const input = document.getElementById("imageInput");
              input?.click();
            }}
          >
            {photoContent}

            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    if (event.target?.result) {
                      setPhoto(event.target.result);
                      form.setFieldValue("photoFile", file);
                    }
                  };
                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
          </Flex>
        </VStack>
        <VStack minW={isMobileOrTablet ? "100%" : "auto"} align="flex-start">
          <Text fontSize="0.8em" color="gray.500">
            Logo
          </Text>
          <Flex
            cursor="pointer"
            borderRadius="md"
            overflow="hidden"
            bg="gray.100"
            w="160px"
            h="160px"
            border="1px solid"
            borderColor="gray.200"
            onClick={() => {
              const input = document.getElementById("logoInput");
              input?.click();
            }}
          >
            {logoContent}

            <input
              type="file"
              id="logoInput"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    if (event.target?.result) {
                      setLogo(event.target.result);
                      form.setFieldValue("logoFile", file);
                    }
                  };
                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
          </Flex>
        </VStack>
      </Stack>

      <HStack w="100%" maxW="600px">
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            Location name
          </FormLabel>
          <Input
            name="name"
            type="text"
            placeholder="Location name"
            value={form.values.name}
            onChange={form.handleChange}
          />
        </FormControl>
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            Cuisine
          </FormLabel>
          <Input
            name="cuisine"
            type="text"
            placeholder="Cuisine"
            value={form.values.cuisine}
            onChange={form.handleChange}
          />
        </FormControl>
      </HStack>

      <Stack direction={["column", "row", "row"]} w="100%">
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            Phone number
          </FormLabel>
          <Input
            w="300px"
            maxW="300px"
            name="phoneNumber"
            type="text"
            placeholder="Phone number"
            value={form.values.phoneNumber}
            onChange={form.handleChange}
          />
        </FormControl>
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            Is published
          </FormLabel>
          <Checkbox
            name="isPublished"
            size="lg"
            isChecked={form.values.isPublished}
            onChange={(e) => {
              form.setFieldValue("isPublished", e.target.checked);
            }}
          />
        </FormControl>
      </Stack>

      <FormControl isRequired={true}>
        <FormLabel fontSize="0.8em" color="gray.500">
          Street address
        </FormLabel>
        <Input
          name="streetAddress"
          type="text"
          placeholder="Street ddress"
          value={form.values.streetAddress}
          onChange={form.handleChange}
        />
      </FormControl>

      <Stack w="100%" maxW="800px" direction={["column", "row"]}>
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            City
          </FormLabel>
          <Input
            name="city"
            type="text"
            placeholder="City"
            value={form.values.city}
            onChange={form.handleChange}
          />
        </FormControl>
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            State
          </FormLabel>
          <StateSelect
            value={form.values.state}
            onChange={(e) => {
              form.setFieldValue("state", e.target.value.toUpperCase());
            }}
          />
        </FormControl>
        <FormControl isRequired={true}>
          <FormLabel fontSize="0.8em" color="gray.500">
            ZIP Code
          </FormLabel>
          <Input
            name="zipcode"
            type="text"
            placeholder="ZIP Code"
            value={form.values.zipcode}
            onChange={form.handleChange}
          />
        </FormControl>
      </Stack>

      <FormControl>
        <FormLabel fontSize="0.8em" color="gray.500">
          Location description
        </FormLabel>
        <Textarea
          name="description"
          h="166px"
          value={form.values.description}
          onChange={form.handleChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel fontSize="0.8em" color="gray.500">
          Allergen message
        </FormLabel>
        <Textarea
          name="allergenMessage"
          h="166px"
          value={form.values.allergenMessage}
          onChange={form.handleChange}
        />
      </FormControl>

      <VStack w="100%" align="flex-start" mt="10px">
        <Text color="teal.600" fontWeight="bold">
          Menus
        </Text>
        {menusContent}
      </VStack>
    </VStack>
  );
});

export default BranchForm;
