import React from "react";
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from "@chakra-ui/react";
import { HiChevronDown } from "react-icons/hi2";

import { useGlobalContext } from "../context/globalContext";

interface Props {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const RangeSliderInput: React.FC<Props> = ({ value, setValue }) => {
  const { isMobile } = useGlobalContext();
  const [showTooltip, setShowTooltip] = React.useState(false);

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          color="gray.600"
          fontWeight="normal"
          variant="outline"
          size="md"
          rightIcon={<HiChevronDown />}
        >
          <Flex align="center">
            <Box>Match</Box>
            <Box ms="5px" color="gray.400" fontSize="0.8em">
              ({value}%)
            </Box>
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent mx={2}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader color="gray.600" fontSize="0.8em" maxW="280px">
          Minimum percent of restaurant's dishes that meet your allergy and
          dietary preferences:
        </PopoverHeader>
        <PopoverBody mb={2}>
          <Slider
            id="slider"
            min={0}
            max={100}
            step={10}
            defaultValue={value}
            colorScheme="teal"
            onChange={(v) => setValue(v)}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip
              hasArrow
              bg="teal.500"
              color="white"
              placement="top"
              isOpen={showTooltip}
              label={`${value}%`}
            >
              <SliderThumb boxSize={isMobile ? 6 : 4} />
            </Tooltip>
          </Slider>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default RangeSliderInput;
