import { useCallback, useState } from "react";

const useLocalStorage = <T,>(
  key: string,
  initialValue: T | null
): [T | null, (value: T | null) => void] => {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error retrieving "${key}" from localStorage:`, error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | null): void => {
      try {
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error(`Error setting "${key}" in localStorage:`, error);
      }
    },
    [key]
  );

  return [storedValue, setValue];
};

export default useLocalStorage;
