import { Token } from "../types/state";
import { LoginForm } from "../types/forms";

import { PublicAPI } from "../../../api";
import {
  ACCESS_TOKEN_EXPIRES,
  REFRESH_TOKEN_EXPIRES,
} from "../../../data/constants";

export const handleLogin = async (
  formData: LoginForm,
  setError: (error: string) => void
): Promise<Token | undefined> => {
  const { email, password } = formData;
  try {
    const res = await PublicAPI.post("account/login/", {
      email,
      password,
    });
    if (res.status === 200) {
      return {
        email,
        accessToken: res.data.access_token,
        refreshToken: res.data.refresh_token,
        datetimeAccessCreated: new Date(),
        datetimeRefreshCreated: new Date(),
        accessExpiresIn: ACCESS_TOKEN_EXPIRES,
        refreshExpiresIn: REFRESH_TOKEN_EXPIRES,
        app: "allergood",
      } as Token;
    }
  } catch (error) {
    // if error.code === "ERR_NETWORK" then show some other message
    setError("Invalid email and password combination.");
  }
};
