import React, { useEffect, useState } from "react";
import { Heading, Spinner, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";

import { RegisterBusinessForm } from "../../features/registration/types/forms";
import { handleRegisterBusiness } from "../../features/registration/services/register";
import { handleGetAccount } from "../../features/authentication/services/account";
import { handleCreateSetupIntent } from "../../features/settings/services/billing";
import { useAuthContext } from "../../features/authentication/context/authContext";
import { useGlobalContext } from "../../context/globalContext";
import useLocalStorage from "../../hooks/useLocalStorage";

import ContactForm from "./components/ContactForm";
import PaymentForm from "./components/PaymentForm";
import { isTokenValid } from "../../features/authentication/utils/tokens";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || "");

interface Props {}

const RegisterBusinessPage: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    setAccount,
    token,
    setToken,
    registerBizActive,
    setRegisterBizActive,
  } = useAuthContext();
  const { isMobile, setModalLoading } = useGlobalContext();
  const [step, setStep] = useLocalStorage("registerStep", "contact");
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const si = searchParams.get("setup_intent");
    const cs = searchParams.get("setup_intent_client_secret");
    if (si && cs) {
      setRegisterBizActive(false);
      setStep("contact");
    }
  }, [searchParams, setStep, setRegisterBizActive]);

  useEffect(() => {
    const getSetupIntent = async () => {
      if (registerBizActive && isTokenValid(token)) {
        const newClientSecret = await handleCreateSetupIntent();
        setClientSecret(newClientSecret);
        setStep("payment");
        setLoading(false);
        setModalLoading(false);
      }
    };

    getSetupIntent();
  }, [
    token,
    registerBizActive,
    setStep,
    setModalLoading,
    setRegisterBizActive,
  ]);

  useEffect(() => {
    if (step === "payment" && !isTokenValid(token)) {
      setStep("contact");
    }
  }, [step, setStep, token]);

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
      businessName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    } as RegisterBusinessForm,
    validate: (values) => {},
    onSubmit: async (values) => {
      setError(null);
      setLoading(true);
      setModalLoading(true);
      const token = await handleRegisterBusiness(values, setError);
      if (token) {
        setRegisterBizActive(true);
        setToken(token); // this triggers setStep("payment")
        const newAccount = await handleGetAccount();
        if (newAccount) {
          setAccount(newAccount);
          return;
        }
      }

      setLoading(false);
      setModalLoading(false);
    },
  });

  let heading;
  let formComponent;
  switch (step) {
    case "contact":
      heading = "Sign up for a business account";
      formComponent = (
        <ContactForm
          form={form}
          error={error}
          loading={loading}
          isMobile={isMobile}
          onContinue={() => {
            form.handleSubmit();
          }}
        />
      );
      break;
    case "payment":
      heading = "Enter payment details";
      formComponent = clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <PaymentForm
            form={form}
            onContinue={() => {
              setStep("contact");
              setRegisterBizActive(false);
            }}
          />
        </Elements>
      ) : (
        <Spinner color="teal.600" />
      );
      break;
    default:
      return <div>idk</div>;
  }

  return (
    <VStack
      flex={1}
      pb="2em"
      w="100%"
      maxW="420px"
      align="center"
      justify={isMobile ? "flex-start" : "center"}
      mb={isMobile ? "40px" : "80px"}
    >
      <VStack align="flex-start" spacing={2} w="100%">
        <Heading color="teal.600" size="md" mb="20px">
          {heading}
        </Heading>
        {formComponent}
      </VStack>
    </VStack>
  );
};

export default RegisterBusinessPage;
