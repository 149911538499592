import React, { useEffect } from "react";
import { Outlet, Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Flex, HStack, Image, Link as ChakraLink, Modal, ModalOverlay } from "@chakra-ui/react";

import Logo from "../assets/images/logo.png";
import PanelWaffle from "../assets/images/panel_waffle.jpg";
import PanelPicnic from "../assets/images/panel_picnic.jpg";
import { isTokenValid } from "../features/authentication/utils/tokens";
import { useGlobalContext } from "../context/globalContext";
import { useAuthContext } from "../features/authentication/context/authContext";

interface Props {}

const LayoutPublic: React.FC<Props> = () => {
  const { token, loginActive, registerBizActive, registerCustActive } = useAuthContext();
  const { isMobile, isMobileOrTablet, modalLoading } = useGlobalContext();
  const location = useLocation();
  const navigate = useNavigate();

  let image;
  switch (location.pathname) {
    case "/login":
      image = PanelPicnic;
      break;
    case "/register":
      image = PanelWaffle;
      break;
    default:
      image = PanelPicnic;
      break;
  }

  // redirect to business registration if started but not complete
  useEffect(() => {
    if (isTokenValid(token)) {
      console.log("token valid");
      if (registerBizActive) {
        navigate("/get-started", { replace: true });
      } else if (registerCustActive) {
        console.log("registerCustActive");
      } else if (loginActive) {
        console.log("loginActive");
      } else {
        console.log("else");
        navigate("/", { replace: true });
      }
    }
  }, [token, navigate, loginActive, registerBizActive, registerCustActive]);

  return (
    <HStack bg="white" h="100%" w="100%" overflowX="hidden" spacing={0}>
      <Modal isOpen={modalLoading} onClose={() => {}}>
        <ModalOverlay zIndex={999} />
      </Modal>
      <Flex
        id="public-layout-scrollable"
        direction="column"
        align="center"
        h="100%"
        flex={4}
        px="1em"
        py={isMobile ? "1em" : "2em"}
        overflowY="scroll"
      >
        <ChakraLink as={RouterLink} to="/" replace>
          <Image src={Logo} w={isMobile ? "180px" : "200px"} mb={isMobile ? "20px" : "20px"} />
        </ChakraLink>
        <Outlet />
      </Flex>
      <Flex h="100%" flex={3} display={isMobileOrTablet ? "none" : "block"}>
        <Image src={image} h="100%" w="100%" objectFit="cover" />
      </Flex>
    </HStack>
  );
};

export default LayoutPublic;
